import { MenuOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Menu } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from '../../auth';
import backy from '../../images/chevron-leftbackbutton.svg';
import GlobalSearch from '../GlobalSearch/GlobalSearch';
import ProfileInfo from '../profileInfo/ProfileInfo';
import style from './Navbar.module.css';
import { useTranslation } from 'react-i18next';
import { handleOnKeyDown } from '../../utils/eventListeners';
import SearchComponent from '../SearchComponent';
import { useMediaQuery } from 'react-responsive';

const Navbar = ({
  isMenuUnfolded,
  setIsMenuUnfolded,
  shrink,
  back,
}: {
  isMenuUnfolded: boolean;
  setIsMenuUnfolded: (callback: (state: boolean) => boolean) => void;
  shrink: boolean;
  back: boolean;
}) => {
  const menuMarginLeft = shrink
    ? isMenuUnfolded
      ? '0'
      : '4.4rem'
    : isMenuUnfolded
      ? '0'
      : '231px';
  const navigate = useNavigate();
  const [globalSearchQuery, setGlobalSearchQuery] = React.useState('');
  const [showGlobalSearch, setShowGlobalSearch] = React.useState(false);
  const globalSearchRef = useRef<HTMLDivElement>(null);
  const backPage = window.location.pathname.split('/')[2];

  const { t } = useTranslation();

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      console.log();
      if (
        globalSearchRef.current &&
        !globalSearchRef.current?.contains(event.target) &&
        showGlobalSearch &&
        !event.target.closest('.ant-menu-horizontal')
      ) {
        setShowGlobalSearch(false);
        setGlobalSearchQuery('');
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [globalSearchRef, showGlobalSearch]);

  const isDesktop = useMediaQuery({ minWidth: 1024 });

  return (
    <>
      <Menu
        mode="horizontal"
        defaultSelectedKeys={['mail']}
        style={{
          paddingLeft: '1.5rem',
          lineHeight: '3.25px',
          minHeight: '4.5rem',
          backgroundColor: '#fff',
          color: '#FFFFFF',
          position: 'fixed',
          width: '100%',
          zIndex: '2',
          marginLeft: menuMarginLeft,
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.12)',
        }}
      >
        {back ? (
          <div
            className={style.backButtonClass}
            onClick={() => navigate(`/${backPage}`)}
            onKeyDown={(e) =>
              handleOnKeyDown(e, () => navigate(`/${backPage}`))
            }
            role="button"
            tabIndex={0}
          >
            <img
              src={backy}
              alt="Back symbol to previous screen"
              style={{ paddingRight: '0.5rem' }}
            />
            {t('Backtoall')}
          </div>
        ) : (
          <></>
        )}
        {(isMobile || isMenuUnfolded) && (
          <Button
            className={style.menuIcon}
            onClick={() =>
              setIsMenuUnfolded((menuState: boolean) => !menuState)
            }
          >
            {isMenuUnfolded ? <MenuOutlined /> : <MenuUnfoldOutlined />}
          </Button>
        )}
        {!isMobile && (
          <div className={style.title}>
            <div
              id={'globalSearch'}
              className={style.globalSearch}
              style={{
                position: 'relative',
                width: isDesktop ? '620px' : '400px',
              }}
            >
              <div className="search-wrapper" ref={globalSearchRef}>
                <SearchComponent
                  onChange={(e) => setGlobalSearchQuery(e.target.value)}
                  placeholder={t('Search')}
                  onSearch={(value) => setGlobalSearchQuery(value)}
                  onInput={() => setShowGlobalSearch(true)}
                  value={globalSearchQuery}
                  allowClear
                />

                <GlobalSearch
                  query={globalSearchQuery}
                  visible={showGlobalSearch}
                  setVisible={setShowGlobalSearch}
                />
              </div>
            </div>

            <div className={style.profile_div}>
              <ProfileInfo />
            </div>
          </div>
        )}
      </Menu>
    </>
  );
};

export default Navbar;
