import { Button, Dropdown, DropdownProps } from 'antd';
import React from 'react';
import { ReactComponent as More } from '../../images/more.svg';
import './KebabMenuComponent.less';

const KebabMenuComponent = ({ ...props }: DropdownProps) => {
  return (
    <Dropdown placement={props.placement || 'bottomLeft'} arrow {...props}>
      <Button className="kebab-menu-button">
        <More />
      </Button>
    </Dropdown>
  );
};

export default KebabMenuComponent;
