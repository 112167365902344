import { ref } from 'firebase/storage';
import { storage } from './firebase/firebaseIndex';

export const isValidStorageRef = (profilePic?: string): boolean => {
  return !(
    ['https', 'data:image'].filter((item) => profilePic?.includes(item))
      .length > 0
  );
};

export const downLoadDisplayPicture = (profilePic?: string) => {
  if (!profilePic || !isValidStorageRef(profilePic)) {
    return profilePic;
  }
  try {
    const storageRef =
      profilePic && isValidStorageRef(profilePic)
        ? ref(storage, profilePic)
        : null;

    const encodedPath = encodeURIComponent(storageRef.fullPath);

    return `https://firebasestorage.googleapis.com/v0/b/${storageRef.bucket}/o/${encodedPath}?alt=media`;
  } catch (error) {
    console.log(error);
    return profilePic;
  }
};
