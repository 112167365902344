import { Col, Row } from 'antd';
import { Suspense, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import Loader from '../../../components/Loader';

import LeftSubNavSettings from './LeftSubNavSettings/LeftSubNavSettings';
import { AppContext, InitialState } from '../../../auth';
import { useOwnerAdminAccess } from '../../../components/RBAC/OwnerAdminAccess';

function SettingsLayout() {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<any>('general');
  const { institute } = useContext(AppContext) as InitialState;

  const hasOwnerAdminAccess = useOwnerAdminAccess();

  const { t } = useTranslation();

  function handleClick(tabName: any) {
    navigate(`${tabName}`);
    setActiveTab(tabName);
  }

  useEffect(() => {
    const currentTab = window.location.pathname.split('/')[3];
    setActiveTab(currentTab || '');
  }, []);

  const menuItems1 = hasOwnerAdminAccess
    ? [
        {
          key: '',
          text: t('General'),
          handleClick: () => handleClick(''),
        },
        ...(institute?.showPreferencesSettings
          ? [
              {
                key: 'preferences',
                text: t('Preferences'),
                handleClick: () => handleClick('preferences'),
              },
            ]
          : []),
        ...(institute?.showCustomFieldSettings
          ? [
              {
                key: 'custom-fields',
                text: t('Custom Fields'),
                handleClick: () => handleClick('custom-fields'),
              },
            ]
          : []),
        ...(institute?.showTeamManagementSettings
          ? [
              {
                key: 'team-management',
                text: t('Team Management'),
                handleClick: () => handleClick('team-management'),
              },
            ]
          : []),

        // {
        //   key: 'billing',
        //   text: t('Billing'),
        //   handleClick: () => handleClick('billing'),
        // },
        // {
        //   key: 'privacy',
        //   text: t('Privacy'),
        //   handleClick: () => handleClick('privacy'),
        // },
      ]
    : [];

  const menuItems2 = [
    {
      key: 'profileDetails',
      text: t('Profile Details'),
      handleClick: () => handleClick('profileDetails'),
    },
    {
      key: 'security',
      text: t('Security'),
      handleClick: () => handleClick('security'),
    },
  ];

  const menuItems3 = hasOwnerAdminAccess
    ? [
        ...(institute?.showSystemSettings
          ? [
              {
                key: 'system',
                text: t('Email'),
                handleClick: () => handleClick('system'),
              },
            ]
          : []),
        ...(institute?.showFunctionalitySettings
          ? [
              {
                key: 'functionality',
                text: t('Functionality'),
                handleClick: () => handleClick('functionality'),
              },
            ]
          : []),
        ...(institute?.showCustomPromptSettings
          ? [
              {
                key: 'custom-prompts',
                text: t('Custom Prompt'),
                handleClick: () => handleClick('custom-prompts'),
              },
            ]
          : []),
      ]
    : [];

  return (
    <Row style={{ width: '100%' }}>
      <Col
        style={{
          width: '232px',
          height: '100%',
          position: 'fixed',
        }}
      >
        <LeftSubNavSettings
          menuItems={[
            {
              title: t('Organization Settings'),
              items: menuItems1,
            },
            {
              title: t('Customization Setting'),
              items: menuItems3,
            },
            // {
            //   title: t('Profile Settings'),
            //   items: menuItems2,
            // },
          ]}
          activeTab={activeTab}
        />
      </Col>

      <Col style={{ width: 'calc(100% - 232px)', left: '232px' }}>
        <Suspense fallback={<Loader />}>
          <div
            style={{
              overflowY: 'auto',
              background: '#F5F5F5',
              paddingTop: '1rem',
            }}
          >
            {hasOwnerAdminAccess ? <Outlet /> : null}
          </div>
        </Suspense>
      </Col>
    </Row>
  );
}

export default SettingsLayout;
