import { Button, Divider } from 'antd';
import React, { Fragment } from 'react';
import './LeftSubNavSettings.less';

interface Props {
  menuItems: Array<{ title?: string; items: Array<any> }>;
  activeTab: string;
}

const LeftSubNavSettings: React.FC<Props> = ({ menuItems, activeTab }) => {
  return (
    <div className="left-navigation-container">
      <div className="left-navigation-inner-container">
        {menuItems.map((value) => {
          return (
            <Fragment key={value?.title}>
              {value?.title && <div className={'navHead'}>{value.title}</div>}
              {value.items.map((item) => (
                <Button
                  type="text"
                  block
                  onClick={item.handleClick}
                  style={{
                    backgroundColor: activeTab === item.key ? '#1570EF' : '',
                    color: activeTab === item.key ? '#fff' : '',
                  }}
                  size="large"
                  className="left-navigation-button"
                >
                  {item.text}
                </Button>
              ))}
              <Divider style={{ margin: '8px 0' }} />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(LeftSubNavSettings);
