import { Dropdown, MenuProps } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { AppContext, InitialState } from '../../auth';
import { logout } from '../../utils/firebase/firebaseIndex';
import styles from './ProfileInfo.module.css';
import profileIcon from '../../images/profileicon.png';
import {
  DownOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  SwapOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { downLoadDisplayPicture } from '../../utils/downloadProfilePicture';
import ensureHttps from '../../utils/ensureHttps';
import { useNavigate } from 'react-router-dom';

const ProfileInfo = () => {
  const { userInfo, institute, studentName } = useContext(
    AppContext
  ) as InitialState;
  const appName = window.location.pathname.split('/')[1];
  const [isPhotoLoading, setIsPhotoLoading] = useState(false);
  const [profilePic, setProfilePic] = useState('');
  const { t } = useTranslation();

  const navigate = useNavigate();

  const getDownloadUrl = (profilePath: string) => {
    if (profilePath !== '' || profilePath !== null) {
      setTimeout(async () => {
        const url = await downLoadDisplayPicture(profilePath);
        setProfilePic(url);
      }, 1000);
    }
  };

  useEffect(() => {
    if (
      userInfo?.profilePic &&
      userInfo?.profilePic !== '' &&
      !isPhotoLoading
    ) {
      setIsPhotoLoading(true);
      getDownloadUrl(userInfo?.profilePic);
    }
  }, [userInfo, isPhotoLoading]);

  const items: MenuProps['items'] = [
    {
      label: (
        <div className={styles.menuItem}>
          <div className={styles.menuIconAi}>
            <UserOutlined style={{ fontWeight: 'bold' }} />
          </div>
          <div className="button__text">{t('My Coach Profile')}</div>
        </div>
      ),
      key: 'profile',
      onClick: () =>
        (window.location.href =
          window.location.origin + `/${appName}` + `/coach/${userInfo?.email}`),
    },
    {
      label: (
        <div className={styles.menuItem}>
          <div className={styles.menuIconAi}>
            <SwapOutlined style={{ fontWeight: 'bold' }} />
          </div>
          <div className="button__text">{`Switch To ${studentName} View`}</div>
        </div>
      ),
      key: '1',
      onClick: () =>
        (window.location.href = ensureHttps(
          institute?.whiteLabel ||
            `${process.env.VITE_APP_JOB_SEEKER_URL}?ref=coach_portal`
        )),
    },
    {
      label: (
        <div className={styles.menuItem}>
          <div className={styles.menuIconAi}>
            <QuestionCircleOutlined />
          </div>
          <div className="button__text">{t('Coach Guide')}</div>
        </div>
      ),
      key: 'helpcenter',
      onClick: () => {
        window.open('https://careerflow.ai/guide-for-coaches', '_blank');
      },
    },
    {
      label: (
        <div className={styles.menuItem}>
          <div className={styles.menuIconAi}>
            <LogoutOutlined />
          </div>
          <div className="button__text">{t('Logout')}</div>
        </div>
      ),
      key: '2',
      onClick: () => {
        logout();
      },
    },
  ];

  return (
    <div>
      <Dropdown trigger={['click']} menu={{ items }} placement="bottomRight">
        <div style={{ padding: '8px', cursor: 'pointer' }}>
          <div
            className={styles.profileDiv}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {`${userInfo?.fname || 'My Profile'} ${userInfo?.lname}`}
            {profilePic ? (
              <img
                src={profilePic}
                alt="avatar symbol"
                height="25"
                width="25"
                className={styles.avatar}
              />
            ) : (
              <img
                src={profileIcon}
                alt="avatar symbol"
                height="25"
                width="25"
                className={styles.avatar}
                style={{ background: 'white', border: 'none' }}
              />
            )}
            <DownOutlined
              size={12}
              style={{ color: '#96A0B5', marginLeft: '6px' }}
            />
          </div>
          <div></div>
        </div>
      </Dropdown>
    </div>
  );
};

export default ProfileInfo;
