import { Avatar, Badge, Tag } from 'antd';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext, InitialState } from '../../auth';
import InstituteService from '../../services/InstituteService';
import styles from './GlobalSearch.module.less';
import { useTranslation } from 'react-i18next';
import { handleOnKeyDown } from '../../utils/eventListeners';
import { Typography } from 'antd';
import { downLoadDisplayPicture } from '../../utils/downloadProfilePicture';
import { useMediaQuery } from 'react-responsive';

const SearchSection = ({ results, name, loading, setVisible }: any) => {
  const { studentName } = useContext(AppContext) as InitialState;
  const { t } = useTranslation();
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const navigate = useNavigate();
  return (
    <div>
      <div className={clsx(styles.sectionHeader, 'large-text')}>{name}</div>
      <div className={styles.sectionResults}>
        {loading ? (
          <div style={{ padding: '1rem' }}>{t('loading')}...</div>
        ) : results.length === 0 ? (
          <div style={{ padding: '1rem' }}>{t('Noresultsfound')}</div>
        ) : (
          results.map((result: any) => (
            <div
              className={styles.resultItem}
              onClick={() => {
                name.toLowerCase() === `${studentName.toLowerCase()}s`
                  ? navigate(`/${studentName.toLowerCase()}/${result.email}`)
                  : navigate(`/coach/${result.email}`);
                setVisible(false);
              }}
              onKeyDown={(e) =>
                handleOnKeyDown(e, () => {
                  name.toLowerCase() === `${studentName.toLowerCase()}s`
                    ? navigate(`/${studentName.toLowerCase()}/${result.email}`)
                    : navigate(`/coach/${result.email}`);
                  setVisible(false);
                })
              }
              role="button"
              tabIndex={0}
            >
              {isDesktop && (
                <Avatar src={result.profilePic}>
                  {!result.profilePic &&
                    result.fullName
                      ?.match(/(\b\S)?/g)
                      ?.join('')
                      ?.match(/(^\S|\S$)?/g)
                      ?.join('')
                      .toUpperCase()}
                </Avatar>
              )}
              <div className={styles.resultName}>
                <div>
                  <Typography.Text
                    style={{
                      width: isDesktop ? 125 : '100%',
                      lineHeight: 'normal',
                    }}
                    ellipsis={true}
                    strong
                  >
                    {result.fullName}
                  </Typography.Text>
                </div>
                {isDesktop && (
                  <div>
                    <Typography.Text type="secondary">
                      {result.email}
                    </Typography.Text>
                  </div>
                )}
                {isDesktop && (
                  <div>
                    {result?.status === 'active' ? (
                      <Tag color="green">
                        <Badge
                          style={{ marginRight: '0.5rem' }}
                          status="success"
                          dot
                          size="small"
                        />
                        {t('Active')}
                      </Tag>
                    ) : (
                      <Tag color="red">
                        <Badge
                          style={{ marginRight: '0.5rem' }}
                          status="error"
                          dot
                          size="small"
                        />
                        {t('Inactive')}
                      </Tag>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

const GlobalSearch = ({
  query,
  visible,
  setVisible,
  wrapperRef,
}: {
  query: string;
  visible: boolean;
  setVisible: any;
  wrapperRef?: any;
}) => {
  const appName = document.location.pathname.split('/')[1];
  const [students, setStudents] = React.useState([]);
  const [studentsLoading, setStudentsLoading] = React.useState(false);
  const [coachesLoading, setCoachesLoading] = React.useState(false);
  const [coaches, setCoaches] = React.useState([]);
  const { studentName } = useContext(AppContext);
  const { t } = useTranslation();

  const mapDataWithPhoto = async (data: any, setDataWithPhoto: any) => {
    const dataWithPhotoPromise = data.map(async (item: any) => {
      return {
        ...item,
        profilePic: await downLoadDisplayPicture(item?.profilePhotoPath),
      };
    });
    await Promise.all(dataWithPhotoPromise).then((dataWithPhoto: any) => {
      setDataWithPhoto(dataWithPhoto);
    });
  };

  React.useEffect(() => {
    let getCoaches: any;
    let getStudents: any;
    if (query.length > 2) {
      getCoaches = setTimeout(() => {
        setCoachesLoading(true);
        InstituteService.getCoaches(appName, undefined, {}, [{ search: query }])
          .then((res: any) => {
            mapDataWithPhoto(res.data, setCoaches);
          })
          .finally(() => {
            setCoachesLoading(false);
          });
      }, 500);
      getStudents = setTimeout(() => {
        setStudentsLoading(true);
        InstituteService.getStudents(appName, undefined, {}, [
          { search: query },
        ])
          .then((res: any) => {
            mapDataWithPhoto(res.data, setStudents);
          })
          .finally(() => {
            setStudentsLoading(false);
          });
      }, 500);
    }
    return () => {
      clearTimeout(getCoaches);
      clearTimeout(getStudents);
      setStudents([]);
      setCoaches([]);
    };
  }, [appName, query]);

  return (
    visible && (
      <>
        <div className={styles.searchRoot} ref={wrapperRef}>
          {query.length < 2 ? (
            <div
              style={{
                padding: '1rem',
                fontSize: '14px',
                color: '#96A0B5',
                // border: '1px solid #E1EDFF',
                display: 'block',
              }}
            >
              <span
                style={{ textWrap: 'wrap' }}
              >{`Enter three or more characters to search ${studentName}s, coach, companies & groups`}</span>
            </div>
          ) : (
            <>
              <SearchSection
                results={students}
                name={`${studentName}s`}
                loading={studentsLoading}
                setVisible={setVisible}
              />
              <SearchSection
                results={coaches}
                name={t('Coaches')}
                loading={coachesLoading}
                setVisible={setVisible}
              />
            </>
          )}
        </div>
      </>
    )
  );
};

export default GlobalSearch;
