import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'material-icons/iconfont/outlined.css';
import 'material-icons/iconfont/filled.css';
import App from './App';
import UserProvider from './auth';
import './index.css';
import RedirectPage from './pages/RedirectPage/RedirectPage';
import reportWebVitals from './reportWebVitals';
import './i18n';

// const DebugRouter = ({ children }: { children: any }) => {
//   const location = useLocation();
//   if (process.env.NODE_ENV === 'development') {
//     console.log(
//       `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(location.state)}`
//     );
//   }

//   return children;
// };

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

window.addEventListener('vite:preloadError', (_event) => {
  window.location.reload(); // for example, refresh the page
});

const appName = window.location.pathname.split('/')[1];

if (process.env.VITE_APP_ENVIRONMENT === 'PRODUCTION') {
  document
    .querySelector('#gsc')
    ?.setAttribute('content', 'odSKtUMRASQiyFyKotptp4Mnx_Y0sLgvazb-j2tpS9E');
  document
    .querySelector('#gsc')
    ?.setAttribute('name', 'google-site-verification');
}

const userAgent = window.navigator.userAgent.toLowerCase(),
  safari = /safari/.test(userAgent),
  ios = /iphone|ipod|ipad/.test(userAgent);

const rootWebView = ReactDOM.createRoot(
  document.getElementById('root-web-view') as HTMLElement
);

if ((ios && !safari) || (!ios && userAgent.includes('wv'))) {
  rootWebView.render(<RedirectPage />);
} else {
  root.render(
    <BrowserRouter basename={'/' + appName}>
      {/* <DebugRouter> */}
      <UserProvider>
        <App />
      </UserProvider>
      {/* </DebugRouter> */}
    </BrowserRouter>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
