import { Avatar, Button, Divider, Row } from 'antd';
import React from 'react';
import styles from '../../pages/StudentDetail/StudentDetail.module.less';

interface Props {
  menuItems: Array<any>;
  activeTab: string;
  userData: any;
  profilePic: string;
  displayTitle: string;
  aiMenuItems?: Array<any> | null;
}

const LeftSubNav: React.FC<Props> = ({
  menuItems,
  activeTab,
  userData,
  profilePic,
  displayTitle,
  aiMenuItems,
}) => {
  return (
    <div style={{ borderRight: '1px solid #F2F4F7', height: '100%' }}>
      <Row>
        <div className={styles.sidenavHead}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar src={profilePic}>
              {!profilePic &&
                userData?.fullName
                  ?.match(/(\b\S)?/g)
                  ?.join('')
                  ?.match(/(^\S|\S$)?/g)
                  ?.join('')
                  .toUpperCase()}
            </Avatar>
            <div style={{ marginLeft: '0.5rem' }}>
              <h4
                style={{
                  maxWidth: '100px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {userData?.fullName ? userData?.fullName : '.....'}
              </h4>
              <p
                style={{
                  color: '#888',
                  fontSize: '12px',
                  marginTop: '-10px',
                  marginBottom: '0',
                }}
              >
                {displayTitle}
              </p>
            </div>
          </div>
        </div>
      </Row>
      <Divider
        style={{
          backgroundColor: 'rgba(80, 141, 232, 0.2)',
          fontSize: '12px',
          fontWeight: 500,
          marginTop: '0rem',
          marginBottom: '10px',
        }}
      />
      <Row>
        <div style={{ width: '100%' }}>
          <div
            style={{
              width: '92.5%',
              margin: '0 auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                borderRadius: '5px',
                padding: '10px',
                backgroundColor: '#fff',
                margin: '5px',
                marginBottom: '2rem',
                width: '90%',
              }}
            >
              <div className={styles.navHead}>Coach Options</div>
              {menuItems.map((item) => (
                <Button
                  type="text"
                  block
                  onClick={item.handleClick}
                  style={{
                    textAlign: 'left',
                    fontSize: '14px',
                    backgroundColor: activeTab === item.key ? '#1570EF' : '',
                    color: activeTab === item.key ? '#fff' : '',
                    borderRadius: '5px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  size="large"
                >
                  {item.icon}
                  {item.text}
                </Button>
              ))}
              {aiMenuItems ? <Divider style={{ margin: '10px 0' }} /> : null}
              {aiMenuItems ? (
                <div className={styles.navHead}>AI Tools</div>
              ) : null}
              {aiMenuItems &&
                aiMenuItems.map((item) => (
                  <Button
                    type="text"
                    block
                    onClick={item.handleClick}
                    style={{
                      textAlign: 'left',
                      fontSize: '14px',
                      backgroundColor: activeTab === item.key ? '#1570EF' : '',
                      color: activeTab === item.key ? '#fff' : '',
                      borderRadius: '5px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    size="large"
                  >
                    {item.icon}
                    {item.text}
                  </Button>
                ))}
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default React.memo(LeftSubNav);
