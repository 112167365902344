import { Fragment } from 'react/jsx-runtime';
import styles from './index.module.css';
import clsx from 'clsx';

const ViewWrapper = ({
  heading,
  primaryActions,
  children,
  stylesHeader,
  stylesHeadingText,
  stylesContent,
  stylesPrimaryActions,
  headerRef,
}: {
  heading: any;
  primaryActions: any;
  children: any;
  stylesHeader?: any;
  stylesHeadingText?: any;
  stylesPrimaryActions?: any;
  stylesContent?: any;
  headerRef?: any;
}) => {
  return (
    <Fragment>
      <div className={clsx(styles.header, stylesHeader)} ref={headerRef}>
        <div className={clsx(styles.headingText, stylesHeadingText)}>
          {heading}
        </div>

        <div className={stylesPrimaryActions}>{primaryActions}</div>
      </div>
      <div className={clsx(styles.content, stylesContent)}>{children}</div>
    </Fragment>
  );
};

export default ViewWrapper;
