export enum Validation {
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  LENGTH = 'LENGTH',
  REGULAR_EXPRESSION = 'REGULAR_EXPRESSION',
  ANY = 'ANY',
}

export enum TextValidation {
  CONTAINS = 'CONTAINS',
  DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
  EMAIL = 'EMAIL',
  URL = 'URL',
  NULL = 'NULL',
}

export enum NumberValidation {
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL_TO = 'GREATER_THAN_OR_EQUAL_TO',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL_TO = 'LESS_THAN_OR_EQUAL_TO',
  EQUAL_TO = 'EQUAL_TO',
  NOT_EQUAL_TO = 'NOT_EQUAL_TO',
  BETWEEN = 'BETWEEN',
  NOT_BETWEEN = 'NOT_BETWEEN',
  IS_NUMBER = 'IS_NUMBER',
  WHOLE_NUMBER = 'WHOLE_NUMBER',
}

export enum LengthValidation {
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL_TO = 'GREATER_THAN_OR_EQUAL_TO',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL_TO = 'LESS_THAN_OR_EQUAL_TO',
  EQUAL_TO = 'EQUAL_TO',
  NOT_EQUAL_TO = 'NOT_EQUAL_TO',
  BETWEEN = 'BETWEEN',
  NOT_BETWEEN = 'NOT_BETWEEN',
}

export type ValidationResponse = {
  condition: TextValidation | NumberValidation | LengthValidation;
  type: Validation;
  value?: string | number | RegExp | [number, number];
};

export function isEmail(value: string): boolean {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(value);
}

export function isURL(value: string): boolean {
  const urlRegex =
    /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:\\/~+#-]*[\w@?^=%&\\/~+#-])?$/;
  return urlRegex.test(value);
}

export function validateResumeAnalysis(
  validation?: ValidationResponse,
  fieldValue?: string | string[] | number
): boolean {
  if (!validation) return true;

  if (validation.type === Validation.ANY) {
    return true;
  }

  if (
    typeof fieldValue === 'string' &&
    validation?.condition !== TextValidation.NULL
      ? !fieldValue
      : false
  )
    return false;

  if (validation.type === Validation.TEXT) {
    const condition = validation.condition as TextValidation;
    if (condition === TextValidation.NULL) {
      return !fieldValue;
    }
    if (typeof fieldValue !== 'string') return false;

    if (condition === TextValidation.CONTAINS) {
      return fieldValue?.includes(validation?.value as string);
    }
    if (condition === TextValidation.DOES_NOT_CONTAIN) {
      return !fieldValue?.includes(validation?.value as string);
    }

    if (condition === TextValidation.EMAIL) {
      return isEmail(fieldValue.toLowerCase());
    }

    if (condition === TextValidation.URL) {
      return isURL(fieldValue.toLowerCase());
    }
    return true;
  }

  if (validation.type === Validation.NUMBER) {
    const condition = validation.condition as NumberValidation;
    const fieldValueAsNumber = Number(fieldValue); // Assuming fieldValue needs to be converted to a number

    if (isNaN(fieldValueAsNumber)) {
      return false; // If fieldValue is not a number, return false
    }

    switch (condition) {
      case NumberValidation.GREATER_THAN:
        return fieldValueAsNumber > (validation.value as number);
      case NumberValidation.GREATER_THAN_OR_EQUAL_TO:
        return fieldValueAsNumber >= (validation.value as number);
      case NumberValidation.LESS_THAN:
        return fieldValueAsNumber < (validation.value as number);
      case NumberValidation.LESS_THAN_OR_EQUAL_TO:
        return fieldValueAsNumber <= (validation.value as number);
      case NumberValidation.EQUAL_TO:
        return fieldValueAsNumber === (validation.value as number);
      case NumberValidation.NOT_EQUAL_TO:
        return fieldValueAsNumber !== (validation.value as number);
      case NumberValidation.BETWEEN:
        // eslint-disable-next-line no-case-declarations
        const [min, max] = validation.value as [number, number];
        return fieldValueAsNumber > min && fieldValueAsNumber < max;
      case NumberValidation.NOT_BETWEEN:
        // eslint-disable-next-line no-case-declarations
        const [minNotBetween, maxNotBetween] = validation.value as [
          number,
          number,
        ];
        return (
          fieldValueAsNumber < minNotBetween ||
          fieldValueAsNumber > maxNotBetween
        );
      case NumberValidation.IS_NUMBER:
        return (
          typeof fieldValueAsNumber === 'number' && !isNaN(fieldValueAsNumber)
        );
      case NumberValidation.WHOLE_NUMBER:
        return Number.isInteger(fieldValueAsNumber);
      default:
        return true;
    }
  }
  if (validation.type === Validation.LENGTH) {
    const condition = validation.condition as LengthValidation;
    const fieldValueAsNumber =
      typeof fieldValue === 'string'
        ? Number(fieldValue)
        : (fieldValue as string[])?.length || 0; // Assuming fieldValue needs to be converted to a number

    if (isNaN(fieldValueAsNumber)) {
      return false; // If fieldValue is not a number, return false
    }

    switch (condition) {
      case LengthValidation.GREATER_THAN:
        return fieldValueAsNumber > (validation.value as number);
      case LengthValidation.GREATER_THAN_OR_EQUAL_TO:
        return fieldValueAsNumber >= (validation.value as number);
      case LengthValidation.LESS_THAN:
        return fieldValueAsNumber < (validation.value as number);
      case LengthValidation.LESS_THAN_OR_EQUAL_TO:
        return fieldValueAsNumber <= (validation.value as number);
      case LengthValidation.EQUAL_TO:
        return fieldValueAsNumber === (validation.value as number);
      case LengthValidation.NOT_EQUAL_TO:
        return fieldValueAsNumber !== (validation.value as number);
      case LengthValidation.BETWEEN:
        // eslint-disable-next-line no-case-declarations
        const [min, max] = validation.value as [number, number];
        return fieldValueAsNumber > min && fieldValueAsNumber < max;
      case LengthValidation.NOT_BETWEEN:
        // eslint-disable-next-line no-case-declarations
        const [minNotBetween, maxNotBetween] = validation.value as [
          number,
          number,
        ];
        return (
          fieldValueAsNumber < minNotBetween ||
          fieldValueAsNumber > maxNotBetween
        );
      default:
        return true;
    }
  }

  if (validation.type === Validation.REGULAR_EXPRESSION) {
    const regexString = validation.value as string;

    // Check if the string starts and ends with /, indicating it is passed with delimiters
    const cleanedRegexString =
      regexString.startsWith('/') && regexString.endsWith('/')
        ? regexString.slice(1, -1) // Remove the leading and trailing slashes
        : regexString;

    // Create the regex object
    const regex = new RegExp(cleanedRegexString);
    // console.log(
    //   'here',
    //   regex.test(fieldValue as string),
    //   validation.value,
    //   fieldValue
    // );
    return regex.test(fieldValue as string);
  }
  return true;
}
