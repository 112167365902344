export enum AccessControl {
  COACH = 'COACH',
  GROUP = 'GROUP',
  ALL = 'ALL',
}

export enum RBAC {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  COACH = 'COACH',
}

export enum PromptType {
  SUMMARY = 'summary',
  EXPERIENCE = 'experiencePoint',
  EDUCATION = 'educationPoint',
  PROJECT = 'projectPoint',
}
