import { Col, Row } from 'antd';
import React from 'react';

type TablePrimaryActionsProps = {
  actions: {
    component?: React.ReactNode;
    colSpan?: number;
    colStyle?: React.CSSProperties;
  }[];
};

const TablePrimaryActionsLayout = ({ actions }: TablePrimaryActionsProps) => {
  return (
    <Row
      gutter={[16, 16]}
      style={{
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      {actions.map((item, i) => (
        <Col key={i} span={item.colSpan} style={item.colStyle}>
          {item.component}
        </Col>
      ))}
    </Row>
  );
};

export default TablePrimaryActionsLayout;
