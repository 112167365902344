import { InputRef } from 'antd';
import './SearchComponent.less';
import Search, { SearchProps } from 'antd/lib/input/Search';
import clsx from 'clsx';

const SearchComponent = ({
  ...props
}: SearchProps & React.RefAttributes<InputRef>) => {
  return (
    <Search
      className={clsx('search-component', props.className)}
      allowClear
      {...props}
    />
  );
};

export default SearchComponent;
