import axios, { InternalAxiosRequestConfig } from 'axios';
import { auth } from '../utils/firebase/firebaseIndex';

export interface Sort {
  field: string;
  order: 'asc' | 'desc';
}

export interface Filters {
  startDate: number | null;
  endDate: number | null;
}

export interface Payload {
  last: any;
  sort: Sort;
  search: string;
  filters: Filters;
  searchFields?: string[];
  pageSize?: number;
  fieldsToSelect?: string[];
}

export const jobTrackerClient = axios.create({
  baseURL: process.env.VITE_APP_BACKEND_BASE_URL + '/jobTracker',
});

jobTrackerClient.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    config.headers['sourceApp'] = `COACH`;
    config.headers['institute'] = window?.location?.pathname?.split('/')[1];
    if (config && config.headers && auth.currentUser) {
      const accessToken = await auth.currentUser.getIdToken();
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },

  (error: any) => Promise.reject(error)
);

const getInstituteGoals = (instituteId: string) => {
  return jobTrackerClient.get(`/goals/${instituteId}`);
};

const getInstituteGoalsV2 = (instituteId: string, payload: Payload) => {
  return jobTrackerClient.post(`/v2/goals/${instituteId}`, payload);
};

const getInstituteGoalsById = (
  instituteId: string,
  goalId: string,
  queryParams: any
) => {
  return jobTrackerClient.get(`/goals/${instituteId}`, {
    params: {
      goalId,
      ...queryParams,
    },
  });
};

const getStudentGoals = (userId: string) => {
  return jobTrackerClient.get(`/goalsProgress`, {
    params: {
      userId,
    },
  });
};

const getGoalJobs = (goalId: string, userId: string) => {
  return jobTrackerClient.get(`/goalsProgress/jobs`, {
    params: {
      goalId,
      userId,
    },
  });
};

const getGoalContacts = (goalId: string, userId: string) => {
  return jobTrackerClient.get(`/goalsProgress/contacts`, {
    params: {
      goalId,
      userId,
    },
  });
};

const postReccuring = (instituteId: string, userId: string) => {
  return jobTrackerClient.post(`/goals/${instituteId}/reccuring`, {
    userId: userId,
  });
};

const getUserDetailById = (userId: string) => {
  return jobTrackerClient.get('/userdetail', {
    params: {
      userId: userId,
    },
  });
};
const updateUserDetailById = (payload: any) => {
  return jobTrackerClient.put('/userdetail', payload);
};

const getJobDetailById = (params: any) => {
  return jobTrackerClient.get('/job/details', {
    params: {
      ...params,
    },
  });
};

const updateJobDetailById = (payload: any) => {
  return jobTrackerClient.put('/job', payload);
};

const updateDocument = (payload: any) => {
  return jobTrackerClient.put('/documents', payload);
};

const getDocumentsById = (userId: string) => {
  return jobTrackerClient.get('/documents', {
    params: {
      userId: userId,
    },
  });
};

const getDocumentByDocId = (userId: string, documentId: string) => {
  return jobTrackerClient.get('/documents', {
    params: {
      userId: userId,
      documentId: documentId,
    },
  });
};

const createGoal = (instituteId: string, payload: any) => {
  return jobTrackerClient.post(`/goals/${instituteId}`, payload);
};

const getCareerflowResumeById = (userId: string, resumeId: any) => {
  return jobTrackerClient.get(`/resumes/${resumeId}`, {
    params: {
      userId: userId,
    },
  });
};

const getStudentContacts = (userId: string, payload) => {
  const url = `/v2/contacts`;
  return jobTrackerClient.post(url, payload, {
    params: {
      userId: userId,
    },
  });
};

const getStudentContactById = (userId: string, contactId: string) => {
  const url = `/contacts/${contactId}`;
  return jobTrackerClient.get(url, {
    params: {
      userId: userId,
    },
  });
};

const jobTrackerService = {
  getInstituteGoals,
  getInstituteGoalsV2,
  getInstituteGoalsById,
  getStudentGoals,
  getGoalJobs,
  getGoalContacts,
  postReccuring,
  getUserDetailById,
  getDocumentsById,
  getDocumentByDocId,
  updateUserDetailById,
  updateDocument,
  getJobDetailById,
  updateJobDetailById,
  createGoal,
  getCareerflowResumeById,
  getStudentContactById,
  getStudentContacts,
};

export default jobTrackerService;
