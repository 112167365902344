import Paragraph, { ParagraphProps } from 'antd/lib/typography/Paragraph';
import { EllipsisConfig } from 'antd/lib/typography/Base';
import { Tooltip } from 'antd';
import { useState } from 'react';

const TooltipParagraph: React.FC<ParagraphProps> = ({
  children,
  ellipsis,
  ...props
}) => {
  const [truncated, setTruncated] = useState(false);

  return (
    <Tooltip title={truncated ? children : undefined}>
      <Paragraph
        {...props}
        ellipsis={{ ...(ellipsis as EllipsisConfig), onEllipsis: setTruncated }}
      >
        {/* NOTE: Fragment is necessary to avoid showing the title */}
        <>{children}</>
      </Paragraph>
    </Tooltip>
  );
};

export default TooltipParagraph;
