import { Button, message, Modal, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { Fragment, useContext } from 'react';
import InstituteService from '../../services/InstituteService';
import { StudentContext } from '../StudentDetail/StudentDetail';
import styles from './Invites.module.css';
import tick from '../../images/tick.png';
import cancelInviteImg from '../../images/cancelinvite.png';
import { AppContext, InitialState } from '../../auth';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddNewButton from '../../components/AddNewButton';
import TableView from '../../components/TableView';
import SearchComponent from '../../components/SearchComponent';
import { useMediaQuery } from 'react-responsive';
import KebabMenuComponent from '../../components/KebabMenuComponent';
import { PlusOutlined } from '@ant-design/icons';
import { LocalStorageKeys, storage } from '../../utils/localStorage';
import OwnerAdminAccess, {
  useOwnerAdminAccess,
} from '../../components/RBAC/OwnerAdminAccess';

export enum INVITE_STATES {
  accepted = 'ACCEPTED',
  invited = 'INVITED',
  canceled = 'CANCELED',
  deactivated = 'DEACTIVATED',
}

const Invites = () => {
  const { studentData } = React.useContext(StudentContext);
  const { t } = useTranslation();
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const hasAdminAccess = useOwnerAdminAccess();

  const cancelInvite = (studentId: string) => {
    InstituteService.cancelInvite(appName, studentId)
      .then(() => {
        getInvites();
        setCancelModal(false);
      })
      .catch((err) => console.log('err', err));
  };
  const reInvite = (studentId: string) => {
    InstituteService.reInvite(appName, studentId, studentData?.fname, studentId)
      .then(() => getInvites())
      .catch((err) => console.log('err', err));
  };

  const ReSendInvite = (studentId: string) => {
    InstituteService.reInvite(appName, studentId, studentData?.fname, studentId)
      .then(() => {
        getInvites();
        setInviteSent(true);
      })
      .catch((err) => console.log('err', err));
  };
  const getDate = (timestamp: number) => {
    const t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(timestamp);
    return moment(t.valueOf()).format('DD MMMM YYYY');
  };
  const columns: ColumnsType<any> = [
    {
      title: t(`Inviteddate`),
      dataIndex: 'date',
      key: 'date',
      width: '15%',
      render: (text: any, record: any) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {getDate(record?.date)}
          </div>
        );
      },
      sorter: (a, b) => {
        const dateA = a.date instanceof Date ? a.date : new Date(a.date);
        const dateB = b.date instanceof Date ? b.date : new Date(b.date);
        return dateB.getTime() - dateA.getTime();
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t(`Name`),
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      render: (text: any, record: any) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>{text}</div>
        );
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t(`Email`),
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      render: (text: any, record: any) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>{text}</div>
        );
      },
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t(`Status`),
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: (text: any, record: any) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {text?.toLowerCase().charAt(0).toUpperCase() +
              text?.toLowerCase()?.slice(1).toLowerCase()}
          </div>
        );
      },
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
    },
    {
      dataIndex: 'action',
      key: 'action',
      width: '25%',
      align: 'center',
      render: (text: any, record: any) => {
        return (
          <Button
            className={styles.resend}
            style={{
              backgroundColor:
                record?.status === INVITE_STATES.canceled ||
                record?.status === INVITE_STATES.accepted
                  ? '#f5f5f5'
                  : 'white',
              color:
                record?.status === INVITE_STATES.canceled ||
                record?.status === INVITE_STATES.accepted
                  ? 'rgb(150, 160, 181)'
                  : '#1570EF',
              border:
                record?.status === INVITE_STATES.canceled ||
                record?.status === INVITE_STATES.accepted
                  ? ' 1px solid rgb(150, 160, 181)'
                  : ' 1px solid #1570EF',
            }}
            disabled={
              record?.status === INVITE_STATES.canceled ||
              record?.status === INVITE_STATES.accepted
            }
            onClick={() => {
              ReSendInvite(record?.key);
              setSelectedStudentId(record?.key);
            }}
          >
            {t('SendReminder')}
          </Button>
        );
      },
    },
    ...(hasAdminAccess
      ? [
          {
            dataIndex: 'action',
            key: 'action',
            width: '25%',
            align: 'center',
            render: (text: any, record: any) => {
              return record?.status === INVITE_STATES.invited ? (
                <Button
                  className={styles.cancel}
                  disabled={record?.status === INVITE_STATES.accepted}
                  onClick={() => {
                    setCancelModal(true);
                    setSelectedStudentId(record?.key);
                  }}
                >
                  {t('CancelInvite')}
                </Button>
              ) : record?.status === INVITE_STATES.canceled ? (
                <Button
                  className={styles.cancel}
                  disabled={record?.status === INVITE_STATES.accepted}
                  onClick={() => reInvite(record?.key)}
                >
                  {t('InviteAgain')}
                </Button>
              ) : null;
            },
          } as any,
        ]
      : []),
  ];
  const [inviteData, setInviteData] = React.useState<Array<any>>([]);
  const appName = window.location.pathname.split('/')[1];
  const [searchValue, setSearchValue] = React.useState('');
  const [inviteSent, setInviteSent] = React.useState<boolean>(false);
  const [cancelModal, setCancelModal] = React.useState<boolean>(false);
  const [selectedStudentId, setSelectedStudentId] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const { studentName } = useContext(AppContext) as InitialState;

  const navigate = useNavigate();

  const getInvites = React.useCallback(() => {
    setLoading(true);
    InstituteService.getAllInvites(appName)
      .then((res) => setInviteData(res?.data))
      .catch((err) => console.log('err', err))
      .finally(() => setLoading(false));
  }, [appName]);

  React.useEffect(() => {
    getInvites();
  }, [getInvites]);

  const getParsedStudentData = (students: any) => {
    const parsedStudents = students
      ?.filter(
        (data: any) =>
          data?.name
            ?.toLocaleLowerCase()
            ?.includes(searchValue.toLocaleLowerCase()) ||
          data?.email
            ?.toLocaleLowerCase()
            ?.includes(searchValue.toLocaleLowerCase())
      )
      .reduce((acc: any, student: any) => {
        const newStudent = {
          key: student?.id,
          name: student?.name,
          date: student?.date?._seconds,
          email: student?.email,
          status: student.status,
        };

        const existingStudent = acc.find((s: any) => s.key === newStudent.key);
        if (
          !existingStudent &&
          (newStudent.status === INVITE_STATES.canceled ||
            newStudent.status === INVITE_STATES.invited)
        ) {
          acc.push(newStudent);
        }

        return acc;
      }, []);

    return parsedStudents;
  };

  const handleSendReminderToAll = async () => {
    const { value } = storage.getItem(
      LocalStorageKeys.LAST_TIME_SEND_REMINDER_TO_ALL
    );
    if (value) {
      message.warn(
        'You cannot send another reminder to everyone within 24 hours'
      );
      return;
    }
    storage.setItem(LocalStorageKeys.LAST_TIME_SEND_REMINDER_TO_ALL, true);
    try {
      await InstituteService.resendInviteToAll(appName);
      message.success('Invites reminder sent to all successfully!');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <Modal
        open={inviteSent}
        className={styles.infoModel}
        maskTransitionName=""
        style={{ top: '10rem' }}
        onCancel={() => setInviteSent(false)}
        footer={null}
      >
        <div className={styles.invitediv}>
          <img src={tick} height={40} alt="tick symbol" />
          <div className={styles.titlemodal}>
            {t('Remindersentsuccessfully')}
          </div>
          <div className={styles.titledes}>
            We have sent an email to {selectedStudentId} with a reminder to
            accept the invitation to join your organization.
          </div>
        </div>
      </Modal>
      <Modal
        open={cancelModal}
        className={styles.infoModel}
        style={{ top: '10rem' }}
        maskTransitionName=""
        onCancel={() => setCancelModal(false)}
        footer={null}
      >
        <div className={styles.invitediv}>
          <img src={cancelInviteImg} height={40} alt="cancel symbol" />
          <div className={styles.titlemodal}>{t('CancelInvite')}</div>
          <div className={styles.titledes}>
            {t('doYouReallyWantToCancelInvite')}
          </div>
          <div style={{ marginTop: '2rem' }}>
            <Button
              onClick={() => setCancelModal(false)}
              className={styles.nobtn}
            >
              {t('noGoBack')}
            </Button>
            <Button
              onClick={() => cancelInvite(selectedStudentId)}
              className={styles.cancelbtn}
            >
              {t('yesCancel')}
            </Button>
          </div>
        </div>
      </Modal>

      <TableView
        tableTitle={
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {`Invited ${studentName}s`}
            {inviteData?.filter((data) => data.status === 'INVITED').length >
              0 && (
              <Tag style={{ marginLeft: '0.5rem' }}>
                {inviteData?.filter((data) => data.status === 'INVITED').length}
              </Tag>
            )}
          </span>
        }
        primaryActions={[
          {
            component: (
              <SearchComponent
                placeholder={t('Search')}
                onChange={(e) => setSearchValue(e.target.value)}
                onSearch={(value) => setSearchValue(value)}
              />
            ),
            colStyle: { width: '463px' },
          },
          ...(isDesktop
            ? [
                {
                  component: (
                    <OwnerAdminAccess>
                      <AddNewButton
                        onClick={() =>
                          navigate(
                            `/${studentName.toLowerCase()}/add${studentName}`
                          )
                        }
                      >
                        Add New {studentName}
                      </AddNewButton>
                    </OwnerAdminAccess>
                  ),
                },
              ]
            : []),
          {
            component: (
              <KebabMenuComponent
                menu={{
                  items: [
                    ...(!isDesktop && hasAdminAccess
                      ? [
                          {
                            label: `Add New ${studentName}`,
                            key: '1',
                            icon: <PlusOutlined />,
                            onClick: () =>
                              navigate(
                                `/${studentName.toLowerCase()}/add${studentName}`
                              ),
                          },
                        ]
                      : []),
                    {
                      label: `Send reminder to all`,
                      key: '2',
                      icon: (
                        <span className="material-icons-outlined">
                          notifications_active
                        </span>
                      ),
                      onClick: handleSendReminderToAll,
                    },
                  ],
                }}
              />
            ),
          },
        ]}
        columns={columns}
        dataSource={getParsedStudentData(inviteData)}
        loading={loading}
      />
    </Fragment>
  );
};

export default Invites;
