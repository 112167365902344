import React, { Fragment, useContext } from 'react';
import { AppContext, InitialState } from '../../../auth';
import { RBAC } from '../../../types/general';

export const useOwnerAdminAccess = () => {
  const { userInfo, institute } = useContext(AppContext) as InitialState;
  const appName = window.location.pathname.split('/')[1];

  if (!institute?.isRBACActive) {
    return true;
  }

  if (![RBAC.OWNER, RBAC.ADMIN].includes(userInfo?.instituteRoles?.[appName])) {
    return false;
  }
  return true;
};

const OwnerAdminAccess = ({ children }: { children?: React.ReactNode }) => {
  const { userInfo, institute } = useContext(AppContext) as InitialState;

  const appName = window.location.pathname.split('/')[1];

  if (!institute?.isRBACActive) {
    return <Fragment>{children}</Fragment>;
  }

  if (![RBAC.OWNER, RBAC.ADMIN].includes(userInfo?.instituteRoles?.[appName])) {
    return null;
  }
  return <Fragment>{children}</Fragment>;
};

export default OwnerAdminAccess;
