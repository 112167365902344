export enum LocalStorageKeys {
  LAST_TIME_SEND_REMINDER_TO_ALL = 'LAST_TIME_SEND_REMINDER_TO_ALL',
  LAST_TIME_SEND_EMAIL_REMINDER = 'LAST_TIME_SEND_EMAIL_REMINDER',
}

export function setItemLocalStorage(key: string, value: string) {
  window.localStorage.setItem(key, value);
}

export function getItemLocalStorage(key: string) {
  return window.localStorage.getItem(key);
}

export function removeItemLocalStorage(key: string) {
  window.localStorage.removeItem(key);
}

export const storage = {
  prefix: 'STORAGE_TIME_CHECK',
  timeSign: '|DIVIDER|',
  setItem(key: string, value: any, time = Date.now() + 24 * 60 * 60 * 1000) {
    // Protect the key from being overwritten
    key = `${this.prefix}${key}`;
    setItemLocalStorage(key, `${time}${this.timeSign}${JSON.stringify(value)}`);
  },
  getItem(key: string) {
    key = `${this.prefix}${key}`;
    let value = getItemLocalStorage(key);
    let time = 0;
    if (value) {
      const index = value.indexOf(this.timeSign);
      time = +value.slice(0, index);

      // Determine if time has expired
      if (time > Date.now()) {
        value = JSON.parse(value.slice(index + this.timeSign.length));
      } else {
        value = null;
        removeItemLocalStorage(key);
      }
    }
    return { value, time };
  },
};
