import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import Button, { ButtonProps } from 'antd/lib/button/button';

const AddNewButton = ({
  children = 'Add new',
  ...props
}: {
  children?: string | React.ReactNode;
} & ButtonProps) => {
  return (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      children={children}
      {...props}
    />
  );
};

export default AddNewButton;
