import { AccessControl, RBAC } from '../types/general';

export function haveAccess({
  userRole,
  accessControl = AccessControl.ALL,
  coachIds,
  groupIds,
  userEmail,
  userGroupIds,
}: {
  userRole: RBAC;
  accessControl?: AccessControl;
  coachIds?: string[];
  groupIds?: string[];
  userEmail?: string;
  userGroupIds?: string[];
}) {
  if (accessControl === AccessControl.ALL) {
    return true;
  }
  if (userRole && (userRole === RBAC.OWNER || userRole === RBAC.ADMIN)) {
    return true;
  }
  if (accessControl === AccessControl.COACH) {
    if (
      coachIds &&
      coachIds.length > 0 &&
      userEmail &&
      coachIds.includes(userEmail)
    ) {
      return true;
    }
  }

  if (accessControl === AccessControl.GROUP) {
    if (
      (groupIds &&
        groupIds.length > 0 &&
        userGroupIds &&
        userGroupIds.length > 0 &&
        groupIds.some((item) => userGroupIds.includes(item))) ||
      (coachIds &&
        coachIds.length > 0 &&
        userEmail &&
        coachIds.includes(userEmail))
    ) {
      return true;
    }
  }

  return false;
}

export function isAssignedStudentOfCurrentUser({
  isRBACActive,
  coachIds,
  userEmail,
}: {
  isRBACActive: boolean;
  coachIds?: string[];
  userEmail?: string;
}) {
  if (!isRBACActive) {
    return true;
  }
  if (
    coachIds &&
    coachIds.length > 0 &&
    userEmail &&
    coachIds.includes(userEmail)
  ) {
    return true;
  }

  return false;
}
