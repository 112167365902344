function ensureHttps(url: string): string {
  // Replace 'http://' with 'https://' if it exists
  if (url.startsWith('http://')) {
    return url.replace(/^http:\/\//i, 'https://');
  }

  // Check if the URL starts with 'https://', if not, prepend it
  if (!url.startsWith('https://')) {
    return `https://${url}`;
  }

  return url;
}
export default ensureHttps;
