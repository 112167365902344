import { Row, Typography } from 'antd';
import Table, { TableProps } from 'antd/lib/table';
import React, { ReactNode, useEffect, useRef } from 'react';
import styles from './TableView.module.css';
import ViewWrapper from '../../pages/ViewWrapper';
import TablePrimaryActionsLayout from '../TablePrimaryActionsLayout';
import clsx from 'clsx';

const { Title } = Typography;

type TableCustom = TableProps<any> & {
  tableRef?: React.Ref<HTMLDivElement> | undefined;
  fetchMoreOnScroll?: () => void;
};

interface TableViewProps extends TableCustom {
  primaryActions: {
    component?: React.ReactNode;
    colSpan?: number;
    colStyle?: React.CSSProperties;
  }[];
  tableTitle?: string | ReactNode;
  headerRef?: React.Ref<HTMLDivElement> | undefined;
}

const TableView = ({
  columns,
  primaryActions,
  rowSelection,
  onChange,
  dataSource,
  loading,
  tableTitle,
  fetchMoreOnScroll,
  tableRef: tableRefRec,
  headerRef,
  ...rest
}: TableViewProps) => {
  const tableRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<any>();

  useEffect(() => {
    if (!fetchMoreOnScroll) return;
    const handleScroll = async (e: HTMLElement) => {
      const { scrollHeight, clientHeight, scrollTop } = e;
      if (scrollHeight - clientHeight - scrollTop < 100) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => fetchMoreOnScroll(), 300);
      }
    };

    const currentTableRef = tableRef.current;

    if (currentTableRef) {
      const tableBody = currentTableRef.querySelector('.ant-table-body');

      if (tableBody) {
        tableBody.addEventListener('scroll', (e) =>
          handleScroll(e.currentTarget as HTMLElement)
        );
      }
    }

    return () => {
      if (currentTableRef) {
        const tableBody = currentTableRef.querySelector('.ant-table-body');
        if (tableBody) {
          tableBody.removeEventListener('scroll', (e) =>
            handleScroll(e.currentTarget as HTMLElement)
          );
        }
      }
    };
  }, [fetchMoreOnScroll, tableRef]);

  return (
    <ViewWrapper
      heading={
        tableTitle && (
          <Title
            style={{
              whiteSpace: 'pre',
            }}
            level={4}
          >
            {tableTitle}
          </Title>
        )
      }
      primaryActions={<TablePrimaryActionsLayout actions={primaryActions} />}
      stylesHeader={styles.tableViewHeader}
      stylesPrimaryActions={styles.stylesPrimaryActions}
      stylesHeadingText={styles.stylesHeadingText}
      headerRef={headerRef}
    >
      <div className={clsx(styles.listViewWrapper, 'list-view-wrapper')}>
        <Row justify={'center'}>
          <Table
            columns={columns}
            onChange={onChange}
            dataSource={dataSource}
            bordered
            style={{
              width: '100%',
              borderLeft: '1px solid #CFD2D7',
              borderRight: '1px solid #CFD2D7',
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              backgroundColor: '#fff',
            }}
            scroll={{
              y: '70vh',
              scrollToFirstRowOnChange: false,
              x: 'max-content',
            }}
            loading={loading}
            pagination={false}
            rowSelection={rowSelection}
            ref={tableRefRec || tableRef}
            {...rest}
          />
        </Row>
      </div>
    </ViewWrapper>
  );
};

export default TableView;
