import { message } from 'antd';
import axios from 'axios';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import ReactGA from 'react-ga4';
import { createSearchParams, useNavigate } from 'react-router-dom';
import InstituteService from '../services/InstituteService';
import { analyticsEvent } from '../utils/analytics';

import {
  instituteApiPath,
  miscApiPath,
  userDetailApiPath,
  userRoleApiPath,
} from '../utils/apiPaths';
import {
  auth,
  logInWithEmailAndPassword,
  logout,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from '../utils/firebase/firebaseIndex';

import { useTranslation } from 'react-i18next';

export type InitialState = {
  signInWithGoogleCall: () => void;
  isLoggedIn: boolean;
  userInfo: any;
  authToken: string | null;
  user?: any;
  newUser: boolean;
  setNewUser: any;
  setUserInfo: any;
  newUserSignupComplete: boolean;
  loading: boolean;
  appConfig: any;
  signupWithEmailAndPassword: (params: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }) => Promise<void>;
  signinWithEmailAndPassword: (
    email: string,
    password: string,
    firstName?: string,
    lastName?: string
  ) => Promise<void>;
  setIsExtensionInstalled: any;
  isExtensionInstalled: any;
  userVerified: boolean;
  studentName: string;
  institute: any;
  setInstitute: any;
  coachInfo: any;
  fetchInstitute: () => void;
};

export const AppContext = createContext<InitialState>({
  signInWithGoogleCall: () => null,
  setIsExtensionInstalled: () => null,
  isLoggedIn: false,
  userInfo: {},
  appConfig: {},
  authToken: null,
  user: {},
  newUser: false,
  setNewUser: () => null,
  setUserInfo: () => null,
  newUserSignupComplete: false,
  loading: false,
  isExtensionInstalled: false,
  signupWithEmailAndPassword: (params: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }) => null,
  signinWithEmailAndPassword: (
    email: string,
    password: string,
    firstName?: string,
    lastName?: string
  ) => null,
  userVerified: false,
  studentName: 'student',
  institute: {},
  setInstitute: (institute: any) => null,
  coachInfo: null,
  fetchInstitute: () => null,
});

interface Props {
  children: any;
}

export let isMobile = false;

function UserProvider({ children }: Props) {
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
  const [user, userLoading] = useAuthState(auth);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [newUserSignupComplete, setNewUserSignupComplete] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [userVerified, setUserVerified] = useState(false);
  const appName = window.location.pathname.split('/')[1];
  const [studentName, setStudentName] = useState('');
  const [appConfig, setAppConfig] = useState({});
  const [userInfo, setUserInfo] = useState({
    fname: '',
    lname: '',
    email: '',
    username: '',
    profilePic: '',
    questionnaire: {},
    phoneNo: '',
    gender: '',
    dob: '',
    userId: '',
    webAppLanguage: '',
  });
  const [coachInfo, setCoachInfo] = useState(null);
  const [institute, setInstitute] = useState<any>({});

  const { i18n } = useTranslation();
  const navigate = useNavigate();
  isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    axios.get(`${miscApiPath}/webappConfig`).then((res) => {
      if (res.data.content) setAppConfig(res.data.content);
    });

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if ('webAppLanguage' in userInfo) {
      i18n.changeLanguage(userInfo?.webAppLanguage);
    }
  }, [i18n, userInfo]);

  useEffect(() => {
    const loggedInApp = localStorage.getItem('loggedInApp');
    if (!user && !userLoading) {
      return localStorage.removeItem('loggedInApp');
    }
    if (loggedInApp && loggedInApp !== appName && user) {
      window.location.href = `/${loggedInApp}`;
    }
  }, [appName, user, userLoading]);

  useEffect(() => {
    if (
      !user &&
      !userLoading &&
      !window.location.pathname.includes('/login') &&
      appName
    ) {
      window.location.href = window.location.origin + `/${appName}` + '/login';
    }
  }, [appName, userLoading, user]);

  useEffect(() => {
    InstituteService.getInstitute(appName).then(({ data }: any) => {
      if (data === 'OK') {
        message.error('Institute url not valid.');
        setTimeout(() => {
          return (window.location.href = window.location.origin);
        }, 1000);
      }
      console.log(data, 'saved');
      setInstitute(data);
    });
  }, [appName]);

  const fetchInstitute = useCallback(() => {
    InstituteService.getInstitute(appName).then(({ data }: any) => {
      console.log(data, 'saved');
      setInstitute(data);
    });
  }, [appName]);

  const isLoggedIn = localStorage.getItem('isLoggedIn');

  const getUserRole = useCallback(() => {
    axios.get(userRoleApiPath).then(async (userRoleGetResponse) => {
      if (
        !(
          userRoleGetResponse.data.instituteRoles?.[appName] === 'COACH' ||
          userRoleGetResponse.data.instituteRoles?.[appName] === 'ADMIN' ||
          userRoleGetResponse.data.instituteRoles?.[appName] === 'OWNER'
        )
      ) {
        message.error(
          "You don't have access to this app. Please contact institute admin."
        );
        return logout();
      }

      setUserInfo((userInfo) => {
        return {
          ...userRoleGetResponse.data,
          ...userInfo,
          username: `${
            userRoleGetResponse.data && userRoleGetResponse.data.fname
          } ${userRoleGetResponse.data && userRoleGetResponse.data.lname}`,
          fname: userRoleGetResponse.data && userRoleGetResponse.data.fname,
          lname: userRoleGetResponse.data && userRoleGetResponse.data.lname,
          email: userRoleGetResponse.data && userRoleGetResponse.data.email,
          userId: userRoleGetResponse.data && userRoleGetResponse.data.id,
        };
      });

      const userRes: any = await axios.get(
        `${instituteApiPath}/institute/${appName}/verifyInstituteCoach/${
          userRoleGetResponse.data && userRoleGetResponse.data.email
        }`
      );
      setUserVerified(true);
    });
  }, [appName]);

  const getUserDetails = useCallback(() => {
    axios.get(userDetailApiPath).then((userDetailGetResponse) => {
      setUserInfo((userInfo: any) => {
        return {
          ...userInfo,
          profilePic:
            userDetailGetResponse.data &&
            userDetailGetResponse.data[0] &&
            userDetailGetResponse.data[0].profilePhoto,
          questionnaire:
            userDetailGetResponse.data &&
            userDetailGetResponse.data[0] &&
            userDetailGetResponse.data[0].questionnaire,
          phoneNo: userDetailGetResponse?.data[0]?.phoneNo,
          gender: userDetailGetResponse?.data[0]?.gender,
          dob: userDetailGetResponse?.data[0]?.dob,
          webAppLanguage: userDetailGetResponse?.data[0]?.webAppLanguage,
        };
      });
    });
  }, []);

  const fetchAppData = useCallback(() => {
    getUserRole();
    getUserDetails();
    InstituteService.getInstitute(appName).then(({ data }: any) => {
      data?.student && data?.student !== ''
        ? setStudentName(data?.student)
        : setStudentName('Student');
      localStorage.setItem('studentName', data?.student || 'Student');
    });
  }, [appName, getUserDetails, getUserRole]);

  useEffect(() => {
    if (!!user && isLoggedIn === 'true' && !newUser) {
      try {
        fetchAppData();
      } catch (err) {
        console.log('err :>> ', err);
      }
    }
  }, [user, appName, isLoggedIn, newUser, fetchAppData]);

  useEffect(() => {
    const storedStudentName = localStorage.getItem('studentName');
    if (storedStudentName) {
      setStudentName(storedStudentName);
    }
  }, []);

  useEffect(() => {
    if (!!user && !newUser) {
      getUserDetails();
    }
  }, [getUserDetails, user, newUser]);

  useEffect(() => {
    if (userInfo?.email) {
      InstituteService.getCoachDetails(appName, userInfo?.email)
        .then(({ data }: any) => setCoachInfo(data))
        .catch((error) => console.log(error));
    }
  }, [appName, userInfo?.email]);

  const signup = async (authResponse: any) => {
    const { displayName } = authResponse.user;
    const numberOfWords = displayName.split(' ').length;
    const firstname = displayName
      ? displayName.split(' ').slice(0, -1).join(' ')
      : '';
    const lastname =
      numberOfWords > 1 && displayName ? displayName.split(' ').pop() : '';

    const userRoleRequestBody = {
      role: 'MENTEE',
      fname: firstname, // get values from usestate object
      lname: lastname,
      mentorStatus: '',
      profilePhoto: authResponse.user.photoURL || '',
      linkedin: '',
      mentorRating: 0,
    };

    await axios.post(userRoleApiPath, userRoleRequestBody);
    fetchAppData();

    localStorage.setItem('isLoggedIn', 'true');
  };

  const sendTokenToChromeExtension = ({ jwt, user }: any) => {
    try {
      chrome.runtime.sendMessage(
        process.env.VITE_APP_EXTENSION_ID,
        { jwt, user },
        (response: any) => {
          if (response && !response.success) {
            console.log('error sending message', response);
            return response;
          }
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const signInWithGoogleCall = async () => {
    analyticsEvent('Sign in with google', 'Login');

    ReactGA.event({
      category: 'Login',
      action: 'Sign in with google',
    });

    try {
      const authResponse: any = await signInWithGoogle();
      try {
        const userRes: any = await axios.get(
          `${instituteApiPath}/institute/${appName}/verifyInstituteCoach/${authResponse.user.email}`
        );
        setUserVerified(true);
      } catch (e) {
        message.error(
          'This email is not registered. Please contact your admin.'
        );
        logout();
      }

      // extension sign in
      const { isNewUser, idToken } = authResponse._tokenResponse;

      setAuthToken(idToken);

      if (isNewUser) {
        setNewUser(true);
        analyticsEvent('New User Sign In', 'SignIn');
        ReactGA.event({
          category: 'SignIn',
          action: 'New User Sign In',
        });
        await signup(authResponse);
        setNewUserSignupComplete(true);
        getUserDetails();
      } else {
        setNewUser(false);
      }
      navigate(`/${studentName.toLowerCase()}`);
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('loggedInApp', appName);

      sendTokenToChromeExtension({
        jwt: idToken,
        user: authResponse.user,
      });

      if (newUser) {
        return;
      }
      getUserDetails();
    } catch {
      navigate('/login');
    }
  };

  const signupWithEmailAndPassword = async ({
    email,
    password,
    firstName,
    lastName,
  }) => {
    try {
      try {
        await axios.get(
          `${instituteApiPath}/institute/${appName}/verifyInstituteCoach/${email}`
        );
      } catch (e) {
        message.error(
          'This email is not registered. Please contact your admin.'
        );
        return;
      }
      // check if the coach exists with this email or not, else cancel the process.
      message.info('Signing up the new user');
      await registerWithEmailAndPassword(email, password);
      localStorage.setItem('loggedInApp', appName);
      localStorage.setItem('isLoggedIn', 'true');
      const userRoleRequestBody = {
        role: 'MENTEE',
        fname: firstName,
        lname: lastName,
        mentorStatus: '',
        profilePhoto: '',
        linkedin: '',
        mentorRating: 0,
      };

      await axios.post(userRoleApiPath, userRoleRequestBody);

      navigate('/');
      ReactGA.event({
        category: 'SignIn',
        action: 'New User Sign In',
      });
      getUserDetails();
    } catch (error: any) {
      message.error(`${error}`.replace('FirebaseError: Firebase:', ''));
      navigate('/login');
    }
  };

  const signinWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    let userRes: any;
    try {
      try {
        userRes = (
          await axios.get(
            `${instituteApiPath}/institute/${appName}/verifyInstituteCoach/${email}`
          )
        ).data;
      } catch (e) {
        message.error(
          'This email is not registered. Please contact your admin.'
        );
        return;
      }

      setUserVerified(true);

      const authResponse = await logInWithEmailAndPassword(email, password);
      const token = await authResponse.user.getIdToken();
      localStorage.setItem('loggedInApp', appName);
      localStorage.setItem('isLoggedIn', 'true');

      navigate('/');
    } catch (error: any) {
      if (
        error.toString() ===
        'FirebaseError: Firebase: Error (auth/user-not-found).'
      ) {
        navigate({
          pathname: '/login',
          search: createSearchParams({
            register: 'true',
          }).toString(),
        });
      } else {
        message.error(error.toString());
      }
    }
  };

  return (
    <AppContext.Provider
      value={{
        signInWithGoogleCall,
        isLoggedIn: !!user,
        userInfo,
        authToken,
        newUser,
        newUserSignupComplete,
        appConfig,
        setNewUser,
        isExtensionInstalled,
        setIsExtensionInstalled,
        setUserInfo,
        loading: userLoading,
        signupWithEmailAndPassword,
        signinWithEmailAndPassword,
        userVerified,
        studentName,
        institute,
        setInstitute,
        coachInfo,
        fetchInstitute,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default UserProvider;
