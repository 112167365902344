import {
  AudioOutlined,
  FileDoneOutlined,
  LinkedinFilled,
  PhoneOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import { Button, Col, Row, Tooltip, message } from 'antd';
import axios from 'axios';
import React, { Suspense, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { AppContext, InitialState } from '../../auth';
import LeftSubNav from '../../components/LeftSubNav/LeftSubNav';
import Loader from '../../components/Loader';
import { haveAccess } from '../../helpers/accessControl';
import aboutSectionImg from '../../images/AboutGeneratorMenu.svg';
import coverLetterImg from '../../images/CoverlettergeneratorMenu.svg';
import headlineImg from '../../images/HeadlineGeneratorMenu.svg';
import postGeneratorImg from '../../images/LinkedINPostGeneratorMenu.svg';
import dash from '../../images/Vectordash.svg';
import docs from '../../images/Vectordocuments.svg';
import jobs from '../../images/Vectorjobs.svg';
import nocontact from '../../images/Vectornocontacts.svg';
import flag from '../../images/flag.svg';
import flagactive from '../../images/flagactive.svg';
import { useStudent } from '../../providers/StudentProvider';
import { StudentContextType } from '../../providers/StudentProvider/context';
import jobTrackerService from '../../services/JobTrackerServices';
import { AccessControl } from '../../types/general';
import { analyticsEvent } from '../../utils/analytics';
import { jobTrackerApiPath } from '../../utils/apiPaths';
import { downLoadDisplayPicture } from '../../utils/downloadProfilePicture';
import { INVITE_STATES } from '../InvitesPage/Invites';
import styles from './StudentDetail.module.less';
import { LocalStorageKeys, storage } from '../../utils/localStorage';
import InstituteService from '../../services/InstituteService';

export const StudentContext = React.createContext<StudentContextType>({
  studentData: null,
  setStudentData: () => null,
  studentDataLoading: false,
});

function StudentDetail() {
  const navigate = useNavigate();
  const initialActiveTab = window.location.pathname.split('/')[4];

  const [activeTab, setActiveTab] = useState<any>(
    initialActiveTab ? initialActiveTab : ''
  );
  const [profilePic, setProfilePic] = React.useState('');
  const [boardLoading, setBoardLoading] = React.useState(false);

  const { studentData, studentDataLoading } = useStudent();

  const appName = document.location.pathname.split('/')[1];
  const [board, setBoard] = React.useState<any>();
  const [sectionList, setSectionList] = React.useState<Array<any>>([]);
  const [deletedJobList, setDeletedJobList] = React.useState<Array<any>>([]);
  const { studentId } = useParams();
  const [reload, setReload] = useState(false);
  const { studentName, institute, userInfo, coachInfo } = useContext(
    AppContext
  ) as InitialState;
  const url = document.location.pathname.split('/')[3];
  const prevurls = useRef(document.location.pathname.split('/')[3]);

  const [instituteInviteStatus, setInstituteInviteStatus] = useState<any>(null);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (initialActiveTab) {
      setActiveTab(initialActiveTab);
    }
  }, [initialActiveTab]);

  React.useEffect(() => {
    if (prevurls.current !== url) {
      setActiveTab('');
      prevurls.current = url;
    }
  }, [studentId, appName, reload, url]);

  React.useEffect(() => {
    if (studentData?.userId) {
      jobTrackerService
        .getUserDetailById(studentData?.userId)
        .then((res) => {
          setInstituteInviteStatus(res?.data?.[0]?.invites?.[appName]);
        })
        .catch((error) => console.log(error));
    }
  }, [studentData, appName]);
  function handleClick(tabName: any) {
    analyticsEvent('studentdetails sidenav tab click', `${tabName}`);
    if (tabName === 'board' && !board) {
      getBoard();
    }
    navigate(`${tabName}`);
    setActiveTab(tabName);
  }

  const handleSendEmailReminder = async () => {
    const { value, time } = storage.getItem(
      LocalStorageKeys.LAST_TIME_SEND_EMAIL_REMINDER
    );
    if (value) {
      message.warn(
        `You can send a reminder once every 60 seconds. ${Math.ceil((time - Date.now()) / 1000)} seconds remaining`
      );
      return;
    }
    storage.setItem(
      LocalStorageKeys.LAST_TIME_SEND_EMAIL_REMINDER,
      true,
      Date.now() + 60 * 1000
    );
    try {
      InstituteService.reInvite(
        appName,
        studentId as string,
        (studentData as any)?.fname || '',
        studentId as string
      );
      message.success('Sent reminder successfully');
    } catch (error) {
      console.log(error);
    }
  };

  const menuItems = [
    {
      key: '',
      text: `${studentName} Details`,
      icon: (
        <img
          src={dash}
          alt="dash"
          style={{
            height: '18px',
            marginRight: '8px',
            filter: activeTab === '' ? 'brightness(0) invert(1)' : '',
          }}
        />
      ),
      handleClick: () => handleClick(''),
    },

    {
      key: 'board',
      text: t('JobBoard'),
      icon: (
        <img
          src={jobs}
          alt="job board"
          style={{
            height: '18px',
            marginRight: '8px',
            filter: activeTab === 'board' ? 'brightness(0) invert(1)' : '',
          }}
        />
      ),
      handleClick: () => handleClick('board'),
    },
    {
      key: 'documents',
      text: t('Documents'),
      icon: (
        <img
          src={docs}
          alt="Documents"
          style={{
            height: '18px',
            marginRight: '8px',
            filter: activeTab === 'documents' ? 'brightness(0) invert(1)' : '',
          }}
        />
      ),
      handleClick: () => handleClick('documents'),
    },
    {
      key: 'resumebuilder',
      text: t('resumebuilder'),
      icon: (
        <FileDoneOutlined
          style={{
            fontSize: '18px',
            height: '18px',
            color: activeTab === 'resumebuilder' ? '#fff' : '#96A0B5',
          }}
        />
      ),
      handleClick: () => handleClick('resumebuilder'),
    },
    {
      key: 'contacts',
      text: t('Contacts'),
      icon: (
        <PhoneOutlined
          style={{
            fontSize: '18px',
            height: '18px',
            color: activeTab === 'contacts' ? '#fff' : '#96A0B5',
          }}
        />
      ),
      handleClick: () => handleClick('contacts'),
    },
    {
      key: 'linkedin',
      text: t('LinkedIn'),
      icon: (
        <LinkedinFilled
          style={{
            fontSize: '20px',
            height: '20px',
            color: activeTab === 'linkedin' ? '#fff' : '#96A0B5',
          }}
        />
      ),
      handleClick: () => handleClick('linkedin'),
    },
    {
      key: 'notes',
      text: t('PrivateNotes'),
      icon: (
        <ProfileOutlined
          style={{
            fontSize: '20px',
            height: '20px',
            color: activeTab === 'notes' ? '#fff' : '#96A0B5',
          }}
        />
      ),
      handleClick: () => handleClick('notes'),
    },
  ];
  const menuItems2 = [
    {
      key: '',
      text: `${studentName} Details`,
      icon: (
        <img
          src={dash}
          alt="dash"
          style={{
            height: '18px',
            marginRight: '8px',
            filter: activeTab === '' ? 'brightness(0) invert(1)' : '',
          }}
        />
      ),
      handleClick: () => handleClick(''),
    },
    {
      key: 'board',
      text: (
        <Tooltip title={t('JobBoard')}>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {t('JobBoard')}
          </div>
        </Tooltip>
      ),
      icon: (
        <img
          src={jobs}
          alt="job board"
          style={{
            height: '18px',
            marginRight: '8px',
            filter: activeTab === 'board' ? 'brightness(0) invert(1)' : '',
          }}
        />
      ),
      handleClick: () => handleClick('board'),
    },
    {
      key: 'documents',
      text: (
        <Tooltip title={t('Documents')}>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {t('Documents')}
          </div>
        </Tooltip>
      ),
      icon: (
        <img
          src={docs}
          alt="Documents"
          style={{
            height: '18px',
            marginRight: '8px',
            filter: activeTab === 'documents' ? 'brightness(0) invert(1)' : '',
          }}
        />
      ),
      handleClick: () => handleClick('documents'),
    },
    {
      key: 'resumebuilder',
      text: (
        <Tooltip title={t('resumebuilder')}>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {t('resumebuilder')}
          </div>
        </Tooltip>
      ),
      icon: (
        <FileDoneOutlined
          style={{
            fontSize: '18px',
            height: '18px',
            marginRight: '8px',
            color: activeTab === 'resumebuilder' ? '#fff' : '#96A0B5',
          }}
        />
      ),
      handleClick: () => handleClick('resumebuilder'),
    },
    {
      key: 'contacts',
      text: (
        <Tooltip title={t('Contacts')}>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {t('Contacts')}
          </div>
        </Tooltip>
      ),
      icon: (
        <PhoneOutlined
          style={{
            fontSize: '18px',
            height: '18px',
            color: activeTab === 'contacts' ? '#fff' : '#96A0B5',
            marginRight: '8px',
          }}
        />
      ),
      handleClick: () => handleClick('contacts'),
    },
    {
      key: 'linkedin',
      text: (
        <Tooltip title={t('LinkedIn')}>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {t('LinkedIn')}
          </div>
        </Tooltip>
      ),
      icon: (
        <LinkedinFilled
          style={{
            fontSize: '20px',
            height: '20px',
            color: activeTab === 'linkedin' ? '#fff' : '#96A0B5',
            marginRight: '8px',
          }}
        />
      ),
      handleClick: () => handleClick('linkedin'),
    },
    {
      key: 'notes',
      text: (
        <Tooltip title={t('PrivateNotes')}>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              marginRight: '8px',
            }}
          >
            {t('PrivateNotes')}
          </div>
        </Tooltip>
      ),
      icon: (
        <ProfileOutlined
          style={{
            fontSize: '20px',
            height: '20px',
            color: activeTab === 'notes' ? '#fff' : '#96A0B5',
            marginRight: '8px',
          }}
        />
      ),
      handleClick: () => handleClick('notes'),
    },
    {
      key: 'goals',
      text: (
        <Tooltip title={t('Goals')}>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {t('Goals')}
          </div>
        </Tooltip>
      ),
      icon: (
        <img
          alt={'goals'}
          src={activeTab === 'goals' ? flagactive : flag}
          style={{
            fontSize: '22px',
            height: '24px',
            marginRight: '4px',
          }}
        />
      ),
      handleClick: () => handleClick('goals'),
    },
  ];

  const aiMenuItems = [
    {
      key: 'coverLetter',
      text: (
        <Tooltip title={t(`CoverLetter`)}>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {t(`CoverLetter`)}
          </div>
        </Tooltip>
      ),
      icon: (
        <img
          src={coverLetterImg}
          alt="dash"
          style={{
            height: '24px',
            marginRight: '4px',
            filter:
              activeTab === 'coverLetter' ? 'brightness(0) invert(1)' : '',
          }}
        />
      ),
      handleClick: () => handleClick('coverLetter'),
    },
    {
      key: 'postGenerator',
      text: (
        <Tooltip title={t('PostGenerator')}>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {t('PostGenerator')}
          </div>
        </Tooltip>
      ),
      icon: (
        <img
          src={postGeneratorImg}
          alt="job board"
          style={{
            height: '24px',
            marginRight: '4px',
            filter:
              activeTab === 'postGenerator' ? 'brightness(0) invert(1)' : '',
          }}
        />
      ),
      handleClick: () => handleClick('postGenerator'),
    },
    {
      key: 'linkedinHeadlineGenerator',
      text: (
        <Tooltip title={t('LinkedinHeadlineGenerator')}>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {t('LinkedinHeadlineGenerator')}
          </div>
        </Tooltip>
      ),
      icon: (
        <img
          src={headlineImg}
          alt="Documents"
          style={{
            height: '24px',
            marginRight: '4px',
            filter:
              activeTab === 'linkedinHeadlineGenerator'
                ? 'brightness(0) invert(1)'
                : '',
          }}
        />
      ),
      handleClick: () => handleClick('linkedinHeadlineGenerator'),
    },
    {
      key: 'linkedinAboutSectionGenerator',
      text: (
        <Tooltip title={t('LinkedinAboutSectionGenerator')}>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {t('LinkedinAboutSectionGenerator')}
          </div>
        </Tooltip>
      ),
      icon: (
        <img
          src={aboutSectionImg}
          alt="Documents"
          style={{
            height: '24px',
            marginRight: '4px',
            filter:
              activeTab === 'linkedinAboutSectionGenerator'
                ? 'brightness(0) invert(1)'
                : '',
          }}
        />
      ),
      handleClick: () => handleClick('linkedinAboutSectionGenerator'),
    },
    {
      key: 'elevatorPitch',
      text: (
        <Tooltip title={t('Elevator Pitch')}>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {t('Elevator Pitch')}
          </div>
        </Tooltip>
      ),
      icon: (
        <AudioOutlined
          style={{
            fontSize: '16px',
            height: '24px',
            left: '2px',
            position: 'relative',
            top: '4px',
            marginRight: '12px',
            color: activeTab === 'elevatorPitch' ? '#fff' : '#96A0B5',
          }}
        />
      ),
      handleClick: () => handleClick('elevatorPitch'),
    },
  ];

  const isStudentOnboarded = () => {
    if (appName === 'careerflow') {
      return null;
    }
    if (studentData.length !== 0 && studentData?.userId === undefined)
      return (
        <div className={styles.container}>
          <Row className={styles.boardHeader}>
            <Col md={8} span={17} className={styles.board_title}></Col>
          </Row>

          <div className={styles.noContact}>
            <div>
              <img src={nocontact} height={110} alt="no contact" />
            </div>
            <div className={styles.headingNotOnboarded}>
              {t('UserNotOnboarded')}
            </div>
            <div className={styles.subheading}>
              {t(
                'This user has not onboarded yet. Once they complete onboarding, you will be able to see and manage their data here.'
              )}
            </div>
            <Button
              style={{ marginTop: '1rem' }}
              onClick={handleSendEmailReminder}
            >
              Send Email Reminder
            </Button>
          </div>
        </div>
      );
    return null;
  };

  const isInviteAccepted = (
    appName: string,
    inviteStatus: string,
    bypassInviteCheck: boolean
  ) => {
    if (bypassInviteCheck) return true;
    if (appName === 'careerflow') return true;
    if (inviteStatus === 'ACCEPTED') return true;
    return false;
  };

  const getBoard = React.useCallback(
    (callback?: any) => {
      setBoardLoading(true);

      if (!studentData?.userId) {
        setBoardLoading(false);
        setBoard(null);
        return;
      }
      axios
        .get(`${jobTrackerApiPath}/board?userId=${studentData?.userId}`)
        .then((res) => {
          setBoard(res.data);
          setSectionList(res.data.sectionList);
          setDeletedJobList(res.data?.deletedJobCardList || []);
          if (callback) callback(res.data);
        })
        .catch((err) => {
          setBoardLoading(false);
          setBoard(null);
        })
        .finally(() => setBoardLoading(false));
    },
    [studentData]
  );
  React.useEffect(() => {
    getBoard();
  }, [getBoard]);

  React.useEffect(() => {
    (async () => {
      const response = await downLoadDisplayPicture(
        studentData?.profilePhotoPath
      );
      setProfilePic(response);
    })();
  }, [studentData?.profilePhotoPath]);

  if (studentDataLoading) return <Loader />;
  return (
    <div className={styles.root}>
      <Row style={{ width: '100%' }}>
        <Col
          style={{
            flex: '0 0 250px',
            width: '230px',
            backgroundColor: '#E1EDFF',
            height: '100%',
            zIndex: 1,
            position: 'fixed',
            minHeight: '90vh',
            bottom: '0rem',
            top: '4.5rem',
          }}
        >
          <LeftSubNav
            profilePic={profilePic}
            userData={studentData}
            menuItems={institute?.goals ? menuItems2 : menuItems}
            activeTab={activeTab}
            displayTitle={studentName}
            aiMenuItems={institute?.aiTools ? aiMenuItems : null}
          />
        </Col>

        <Col style={{ marginLeft: '230px', width: 'calc(100% - 230px)' }}>
          <Suspense fallback={<Loader />}>
            {initialActiveTab &&
            !haveAccess({
              userRole: userInfo?.instituteRoles?.[appName],
              accessControl: institute?.accessControl,
              coachIds: studentData?.coachIds?.map((item) => item?.email),
              userEmail: userInfo?.email,
              groupIds: studentData?.groups?.map((item) => item?.id),
              userGroupIds: coachInfo?.groups?.map((item) => item?.id),
            }) ? (
              <div className={styles.container}>
                <div className={styles.noData}>
                  <div>
                    <img src={nocontact} height={110} alt="no contact" />
                  </div>
                  <div className={styles.heading}>
                    {institute?.accessControl === AccessControl.COACH
                      ? `You are only allowed to view the details of ${studentName.toLowerCase()}s who have been assigned to you. If you require access to additional ${studentName.toLowerCase()}s, please contact your administrator for assistance.`
                      : institute?.accessControl === AccessControl.GROUP
                        ? `You are only allowed to view the details of ${studentName.toLowerCase()}s who have been assigned to you, or you are in a common group with. If you require access to additional ${studentName.toLowerCase()}s, please contact your administrator for assistance.`
                        : ''}
                  </div>
                  <div className={styles.subheading}></div>
                </div>
              </div>
            ) : initialActiveTab &&
              activeTab !== 'edit' &&
              isStudentOnboarded() ? (
              isStudentOnboarded()
            ) : initialActiveTab &&
              activeTab !== 'edit' &&
              !isInviteAccepted(
                appName,
                instituteInviteStatus,
                institute?.createTempUserRoleForNewJobSeekers ||
                  institute?.dontSendStudentInvite
              ) ? (
              <div className={styles.container}>
                <div className={styles.noData}>
                  <div className={styles.heading}>
                    <div className="mb-6">
                      <img src={nocontact} height={110} alt="no contact" />
                    </div>

                    {instituteInviteStatus === INVITE_STATES.deactivated
                      ? `This ${studentName.toLowerCase()} has been deactivated and is no longer sharing data with your organization, nor is your organization sharing data and resources with them`
                      : instituteInviteStatus === INVITE_STATES.invited ||
                          instituteInviteStatus === INVITE_STATES.canceled
                        ? `This  ${studentName.toLowerCase()} has not yet accepted your invitation to join your organization. Please request them to do so.`
                        : `This  ${studentName.toLowerCase()} has not yet accepted your invitation to join your organization. Please request them to do so.`}
                  </div>
                  <Button
                    style={{ marginTop: '1rem' }}
                    onClick={handleSendEmailReminder}
                  >
                    Send Email Reminder
                  </Button>
                </div>
              </div>
            ) : (
              <Outlet
                context={{
                  board,
                  sectionList,
                  setSectionList,
                  deletedJobList,
                  boardLoading,
                  getBoard,
                  student: studentData,
                  reload,
                  setReload,
                  studentInvites: instituteInviteStatus,
                  handleSendEmailReminder,
                }}
              />
            )}
          </Suspense>
        </Col>
      </Row>
    </div>
  );
}

export default StudentDetail;
