const innerConfig = {
  baseUrl: process.env.VITE_APP_BACKEND_BASE_URL,
  pythonBaseGraphUrl: process.env.VITE_APP_PYTHON_BACKEND_BASE_GRAPH_URL,
  pythonBaseUrl: process.env.VITE_APP_PYTHON_BACKEND_BASE_URL,
  oneClickBaseUrl: process.env.VITE_APP_ONE_CLICK_BACKEND_BASE_URL,
};
const appName = window.location.pathname.split('/')[1];

export const config = innerConfig;

const version = '/v2';

export const instituteApiPath = `${config.baseUrl}/instituteApp`;
export const instituteAppApiPath = `${config.baseUrl}/instituteApp/institute`;
export const linkedinProfileApiPath = `${config.baseUrl}/chromeExtension/linkedinProfile`;
export const jobTrackerApiPath = `${config.baseUrl}/jobTracker`;
export const saveQueryApiPath = `${jobTrackerApiPath}/saveQuery`;
export const saveAllQueriesPath = `${jobTrackerApiPath}/queries`;
export const userRoleApiPath = `${jobTrackerApiPath}/user-role`;
export const userDetailApiPath = `${jobTrackerApiPath}/userdetail`;
export const miscApiPath = `${config.baseUrl}/faangpath/misc`;
export const shareQueryApiPath = `${jobTrackerApiPath}/shareQuery`;
export const likeApiPath = `${jobTrackerApiPath}/like`;
export const queryFeedbackApiPath = `${jobTrackerApiPath}/queryFeedback`;
export const skillmatchApiPath = `${jobTrackerApiPath}/skillmatch`;
export const addCoachApiPath = `${config.baseUrl}/instituteApp/institute/${appName}/addCoach`;
export const addStudentApiPath = `${config.baseUrl}/instituteApp/institute/${appName}/addStudent`;
export const addBulkStudentsApiPath = `${config.baseUrl}/instituteApp/institute/${appName}/bulkAddStudent`;
export const updateStudentApiPath = `${config.baseUrl}/instituteApp/institute/${appName}/updateStudent`;
export const addGroupApiPath = `${config.baseUrl}/instituteApp/institute/${appName}/addGroup`;
export const groupApiPath = `${config.baseUrl}/instituteApp/institute/${appName}/group`;
export const updateCoachApiPath = `${config.baseUrl}/instituteApp/institute/${appName}/updateCoach`;
export const updateCoachRoleApiPath = `${config.baseUrl}/instituteApp/institute/${appName}/updateCoachRole`;
export const instituteCustomizationApiPath = `${config.baseUrl}/instituteApp/institute/${appName}/customization`;
export const instituteCustomResumeAnalysisApiPath = `${config.baseUrl}/instituteApp/institute/${appName}/custom-resume-analysis`;
export const notesApiPath = `/notes`;
// export const addJobApiPath = `${config.baseUrl}/instituteApp/institute/${appName}/addJob`;
export const addJobApiPath = `${config.baseUrl}/instituteApp/institute/${appName}/addJob${version}`;
export const updateApiPath = `${config.baseUrl}/instituteApp/institute/${appName}/updateJob`;
export const aiServiceBasePath = `${config.baseUrl}/aiTools`;
export const aiCoverLetterApiPath = `${aiServiceBasePath}/generate/cover-letter`;
export const elevatorPitchApiPath = `${aiServiceBasePath}/generate/elevatorPitch`;
export const linkedinAboutApiPath = `${aiServiceBasePath}/generate/linkedin/about`;
export const linkedinHeadlineApiPath = `${aiServiceBasePath}/generate/linkedin/headline`;
export const aiPostApiPath = `${aiServiceBasePath}/generate/linkedin-post`;
export const pythonGraphUrl = `${config.pythonBaseGraphUrl}`;
