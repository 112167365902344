import { Button, Input } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import careerflowLogo from '../../images/newLogo.png';

const SelectInstitute = () => {
  const navigate = useNavigate();
  const [orgUrl, setOrgUrl] = useState('');
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        textAlign: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src={careerflowLogo}
        alt="CareerFlow"
        style={{ width: '200px', objectFit: 'contain' }}
      />
      <div
        style={{
          background: 'white',
          border: '1px solid lightgray',
          borderRadius: '5px',
          padding: '2rem',
          marginTop: '1rem',
          width: '400px',
        }}
      >
        <h2 style={{ fontWeight: '600' }}>Log in to Your Organization</h2>
        <div style={{ marginTop: '1.5rem' }}>
          https://coach.careerflow.ai/
          <Input
            id="appname"
            onChange={(e) => setOrgUrl(e.target.value)}
            style={{ width: '150px' }}
            type="text"
            placeholder="your-org-url"
          />
        </div>
        <div>
          <Button
            onClick={() => navigate('/' + orgUrl)}
            style={{ marginTop: '1.5rem', width: '100%' }}
            type="primary"
          >
            Login
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SelectInstitute;
