import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React, { lazy, Suspense, useContext, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import ReactGA from 'react-ga4';
import { initDB } from 'react-indexed-db-hook';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import packageJson from '../package.json';
import './App.less';
import { AppContext, InitialState } from './auth';
import './auth/axios';
import componentLoader from './components/ComponentLoader/ComponentLoader';
import Loader from './components/Loader';
import { analyticsEvent } from './utils/analytics';

import JobsLib from '@careerflow/common-components/dist/components/Jobs';
import { useAiPopupStore } from '@careerflow/common-components/dist/components/ResumeBuilder/store/resumeAiPopup';
import {
  RESET_RESUME_SETTING,
  useResumeSettingsStore,
} from '@careerflow/common-components/dist/components/ResumeBuilder/store/resumeSettings';
import InitService, {
  APP,
} from '@careerflow/common-components/dist/services/InitService';
import { config } from './utils/apiPaths';
import {
  analytics,
  appCheck,
  auth,
  functions,
  logInWithEmailAndPassword,
  logout,
  perf,
  registerWithEmailAndPassword,
  signInWithGoogle,
  storage,
  storageRef,
} from './utils/firebase/firebaseIndex';

import bg from './locales/bg/translation.json';
import cs from './locales/cs/translation.json';
import da from './locales/da/translation.json';
import de from './locales/de/translation.json';
import en from './locales/en/translation.json';
import es from './locales/es/translation.json';
import et from './locales/et/translation.json';
import fi from './locales/fi/translation.json';
import fr from './locales/fr/translation.json';
import hi from './locales/hi/translation.json';
import hr from './locales/hr/translation.json';
import hu from './locales/hu/translation.json';
import it from './locales/it/translation.json';
import lt from './locales/lt/translation.json';
import lv from './locales/lv/translation.json';
import nl from './locales/nl/translation.json';
import no from './locales/no/translation.json';
import pl from './locales/pl/translation.json';
import pt from './locales/pt/translation.json';
import ro from './locales/ro/translation.json';
import sk from './locales/sk/translation.json';
import sl from './locales/sl/translation.json';
import sr from './locales/sr/translation.json';
import sv from './locales/sv/translation.json';
import zh from './locales/zh/translation.json';
import { CommonLayout, StudentLayout, StudentProviderLayout } from './Layout';
import SelectInstitute from './pages/SelectInstitute';
import SettingsLayout from './pages/Settings/SettingsLayout/SettingsLayout';
import StudentDetail, {
  StudentContext,
} from './pages/StudentDetail/StudentDetail';

const Coach = lazy(() => componentLoader(() => import('./pages/Coach')));

const AddStudent = lazy(() =>
  componentLoader(() => import('./pages/AddStudent'))
);

const ComingSoon = lazy(() =>
  componentLoader(() => import('./components/ComingSoon/ComingSoon'))
);

const Dashboard = lazy(() =>
  componentLoader(() => import('./pages/Dashboard'))
);

const JobTracker = lazy(() =>
  componentLoader(() => import('./components/JobTracker/JobTracker'))
);

const ProfilePage = lazy(() =>
  componentLoader(() => import('./components/ProfilePage/ProfilePage'))
);

const AuthScreen = lazy(() =>
  componentLoader(() => import('./components/Signup/AuthScreen'))
);

const Setting = lazy(() =>
  componentLoader(() => import('./components/Setting/Setting'))
);

const Settings = lazy(() => componentLoader(() => import('./pages/Settings')));

const Preferences = lazy(() =>
  componentLoader(() => import('./pages/Settings/Preferences'))
);

const CustomFields = lazy(() =>
  componentLoader(() => import('./pages/Settings/CustomFields'))
);

const CustomPrompts = lazy(() =>
  componentLoader(() => import('./pages/Settings/CustomPrompts'))
);

const CustomPromptsTemplateEditor = lazy(() =>
  componentLoader(
    () =>
      import('./pages/Settings/CustomPrompts/TemplateEditor/EditorPageFinal')
  )
);

const TeamManagement = lazy(() =>
  componentLoader(() => import('./pages/Settings/TeamManagement'))
);

const Billing = lazy(() =>
  componentLoader(() => import('./pages/Settings/Billing'))
);
const Privacy = lazy(() =>
  componentLoader(() => import('./pages/Settings/Privacy'))
);
const ProfileDetails = lazy(() =>
  componentLoader(() => import('./pages/Settings/ProfileDetails'))
);
const Security = lazy(() =>
  componentLoader(() => import('./pages/Settings/Security'))
);
const System = lazy(() =>
  componentLoader(() => import('./pages/Settings/System'))
);

const Functionality = lazy(() =>
  componentLoader(() => import('./pages/Settings/Functionality'))
);

const LinkedIn = lazy(() =>
  componentLoader(
    () => import('./pages/Settings/Functionality/General/LinkedIn')
  )
);

const AddNewLinkedInCheckList = lazy(() =>
  componentLoader(
    () =>
      import(
        './pages/Settings/Functionality/General/LinkedIn/AddNewLinkedInCheckList'
      )
  )
);

const AddNewResumeInCheckList = lazy(() =>
  componentLoader(
    () =>
      import(
        './pages/Settings/Functionality/AIFunctionality/ResumeBuilder/AddNew'
      )
  )
);

const AddNewResumeInCheckListPersonalInformation = lazy(() =>
  componentLoader(
    () =>
      import(
        './pages/Settings/Functionality/AIFunctionality/ResumeBuilder/PersonalInformation'
      )
  )
);

const ResumeBuilderFunctionality = lazy(() =>
  componentLoader(
    () => import('./pages/Settings/Functionality/AIFunctionality/ResumeBuilder')
  )
);

const AddCoach = lazy(() => componentLoader(() => import('./pages/AddCoach')));

const CoachDetail = lazy(() =>
  componentLoader(() => import('./pages/CoachDetail'))
);

const CoachOverview = lazy(() =>
  componentLoader(() => import('./pages/CoachDetail/Overview'))
);

const Groups = lazy(() => componentLoader(() => import('./pages/Groups')));

const Jobs = lazy(() => componentLoader(() => import('./pages/Jobs')));

const StudentOverview = lazy(() =>
  componentLoader(() => import('./pages/StudentDetail/Overview'))
);

const EditStudent = lazy(() =>
  componentLoader(() => import('./pages/EditStudent'))
);

const EditCoach = lazy(() =>
  componentLoader(() => import('./pages/EditCoach'))
);

const PrivateNotes = lazy(() =>
  componentLoader(() => import('./pages/PrivateNotes/PrivateNotes'))
);

const LinkedinMakeOver = lazy(() =>
  componentLoader(() => import('./pages/LinkedinMakeOver'))
);

const CoverLetter = lazy(() =>
  componentLoader(() => import('./components/AITools/CoverLetter'))
);

const PostGenerator = lazy(() =>
  componentLoader(() => import('./components/AITools/PostGenerator'))
);

const LinkedinAboutGenerator = lazy(() =>
  componentLoader(() => import('./components/AITools/LinkedinAboutGenerator'))
);

const LinkedinHeadlineGenerator = lazy(() =>
  componentLoader(
    () => import('./components/AITools/LinkedinHeadlineGenerator')
  )
);

const ResumeReview = lazy(() =>
  componentLoader(() => import('./pages/ResumeReview'))
);

const Goals = lazy(() => componentLoader(() => import('./pages/Goals/Goals')));

const GoalsDetail = lazy(() =>
  componentLoader(() => import('./pages/Goals/GoalsDetails'))
);

const StudentGoals = lazy(() =>
  componentLoader(() => import('./pages/Goals/StudentGoals'))
);

const StudentGoalDetails = lazy(() =>
  componentLoader(() => import('./pages/Goals/StudentGoalsDetails'))
);

const Invites = lazy(() =>
  componentLoader(() => import('./pages/InvitesPage/Invites'))
);

const ContactList = lazy(() =>
  componentLoader(() => import('./components/Contacts/ContactList'))
);

const InstituteDashboard = lazy(() =>
  componentLoader(() => import('./pages/InstituteDashboard'))
);

const InstituteReports = lazy(() =>
  componentLoader(() => import('./pages/Reports'))
);

const CreateGoal = lazy(() =>
  componentLoader(() => import('./pages/CreateGoal/CreateGoal'))
);

const Linkedin = lazy(() =>
  componentLoader(() => import('./components/Linkedin'))
);

const ElevatorPitch = lazy(() =>
  componentLoader(() => import('./components/AITools/ElevatorPitch'))
);

const AllGoals = lazy(() =>
  componentLoader(() => import('./pages/CreateGoal/AllGoals'))
);

const NotFound = lazy(() =>
  componentLoader(() => import('./components/NotFound'))
);

const ResumeBuilderList = lazy(() =>
  componentLoader(() => import('./pages/ResumeBuilderList'))
);

const ResumeBuilder = lazy(() =>
  componentLoader(() => import('./pages/ResumeBuilder'))
);

const Documents = lazy(() =>
  componentLoader(() => import('./pages/Documents'))
);

const ViewAndDownloadOptimizedResume = lazy(() =>
  componentLoader(() => import('./pages/ViewAndDownloadOptimizedResume'))
);

const Resources = lazy(() =>
  componentLoader(() => import('./pages/Resources'))
);
const Checklist = lazy(() =>
  componentLoader(() => import('./pages/Checklist'))
);

const CreateChecklist = lazy(() =>
  componentLoader(
    () => import('./pages/Checklist/CreateChecklist/CreateChecklist')
  )
);

const ChecklistDetails = lazy(() =>
  componentLoader(
    () => import('./pages/Checklist/ChecklistDetails/ChecklistDetails')
  )
);

const ChecklistParticipantDetails = lazy(() =>
  componentLoader(
    () =>
      import(
        './pages/Checklist/ChecklistParticipantDetails/ChecklistParticipantDetails'
      )
  )
);

const DBConfig = {
  name: 'CareerflowDB',
  version: 1,
  objectStoresMeta: [
    {
      store: 'resume',
      storeConfig: { keyPath: 'name', autoIncrement: true },
      storeSchema: [
        { name: 'name', keypath: 'name', options: { unique: true } },
      ],
    },
  ],
};

initDB(DBConfig);
function App() {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const {
    loading,
    studentName,
    setIsExtensionInstalled,
    institute,
    setInstitute,
  } = useContext(AppContext) as InitialState;
  const studentNameLowerCase = studentName.toLowerCase();

  const location = useLocation();

  const appName = window.location.pathname.split('/')[1];

  const setResumeSettings = useResumeSettingsStore(
    (state: any) => state?.setResumeSettings
  );

  const { setAiPopupVisible } = useAiPopupStore();

  useEffect(() => {
    if (!process.env.VITE_APP_ANALYTICS_MEASUREMENT_ID) return;
    ReactGA.initialize(process.env.VITE_APP_ANALYTICS_MEASUREMENT_ID);
    ReactGA.send('pageview');
  }, []);

  React.useEffect(() => {
    if (!process.env.VITE_APP_ANALYTICS_MEASUREMENT_ID) return;
    ReactGA.initialize(process.env.VITE_APP_ANALYTICS_MEASUREMENT_ID);
    analyticsEvent('pageview');
  }, [location]);

  React.useEffect(() => {
    window.chrome?.runtime?.sendMessage(
      process.env.VITE_APP_EXTENSION_ID,
      { ping: true },
      (res) => {
        if (res) setIsExtensionInstalled(true);
      }
    );
  }, [setIsExtensionInstalled]);

  React.useEffect(() => {
    if (process.env.VITE_APP_SENTRY_DSN)
      Sentry.init({
        dsn: process.env.VITE_APP_SENTRY_DSN,
        integrations: [new BrowserTracing()],
        release: packageJson.version,
        tracesSampleRate: 0,
        sampleRate: 0.9,
        environment: process.env.VITE_APP_ENVIRONMENT,
      });
  }, [appName, navigate]);

  const handleClearResumeSettingsStore = () => {
    setResumeSettings(RESET_RESUME_SETTING);

    setAiPopupVisible(false);
  };

  useEffect(() => {
    InitService.init({
      baseURL: config.baseUrl || '',
      pythonBaseUrl: config.pythonBaseUrl || '',
      oneClickBaseUrl: config.oneClickBaseUrl || '',
      appContext: AppContext,
      firebaseMethods: {
        analytics,
        appCheck,
        auth,
        functions,
        logInWithEmailAndPassword,
        logout,
        perf,
        registerWithEmailAndPassword,
        signInWithGoogle,
        storage,
        triggerResetEmail: () => null,
        storageRef,
      },
      app: APP.COACHING_WEBAPP,
      studentContext: StudentContext,
      locale: {
        en,
        bg,
        cs,
        da,
        de,
        es,
        et,
        fi,
        fr,
        hi,
        hr,
        hu,
        it,
        lt,
        lv,
        nl,
        no,
        pl,
        pt,
        ro,
        sk,
        sl,
        sr,
        sv,
        zh,
      } as any,
    });
  }, []);

  if (loading) return <Loader />;
  else
    return (
      <div className="App">
        <Suspense fallback={<Loader />}>
          {user ? (
            <Routes>
              <Route element={<CommonLayout institute={institute} />}>
                <Route path="/dashboard" element={<InstituteDashboard />} />
                <Route
                  path="/company"
                  element={
                    <div style={{ marginTop: '5rem' }}>
                      <ComingSoon featureName={'Tools'} checked={false} />
                    </div>
                  }
                />
                <Route
                  path="/reports"
                  element={
                    institute?.enableReports ? (
                      <InstituteReports />
                    ) : (
                      <div style={{ marginTop: '5rem' }}>
                        <ComingSoon checked={false} />
                      </div>
                    )
                  }
                />
                <Route
                  path="/resume/download/:optimizedResumeId"
                  element={<ViewAndDownloadOptimizedResume />}
                />
                <Route path="/goals" element={<Goals />} />
                <Route path="/goals/create/hidden" element={<CreateGoal />} />
                <Route path="/goals/:id" element={<GoalsDetail />} />

                <Route path="/checklists" element={<Checklist />} />
                <Route path="/checklists/add" element={<CreateChecklist />} />
                <Route
                  path="/checklists/edit/:id"
                  element={<CreateChecklist />}
                />
                <Route path="/checklists/:id" element={<ChecklistDetails />} />
                <Route
                  path="/checklists/:id/participant/:participantId"
                  element={<ChecklistParticipantDetails />}
                />
                <Route
                  path="/ai/linkedinmakeover/:studentId"
                  element={<LinkedinMakeOver />}
                />
                <Route
                  path="/ai/resumereview/:studentId"
                  element={<ResumeReview />}
                />
                <Route
                  path="/ai/coverletter/:studentId"
                  element={<CoverLetter />}
                />

                <Route
                  path="contacts"
                  element={
                    <div style={{ height: '80vh' }}>
                      <ContactList mode={'institute'} />
                    </div>
                  }
                />
                <Route
                  path="contacts/:id"
                  element={
                    <div style={{ height: '80vh' }}>
                      <ContactList mode={'institute'} />
                    </div>
                  }
                />

                <Route path={`/${studentName}`} element={<Dashboard />} />
                <Route path="/coach" element={<Coach />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route
                  path="/resources"
                  element={<Resources instituteLogo={institute?.logoUrl} />}
                />
                <Route path="/invites" element={<Invites />} />
                <Route
                  path="/dashboard"
                  element={
                    <div style={{ marginTop: '5rem' }}>
                      <ComingSoon featureName={'Tools'} checked={false} />
                    </div>
                  }
                />
                <Route element={<SettingsLayout />} path="/settings">
                  <Route path="" element={<Settings />}></Route>
                  <Route
                    path="preferences"
                    element={
                      <Preferences
                        instituteData={institute}
                        setInstitute={setInstitute}
                      />
                    }
                  ></Route>
                  <Route
                    path="custom-fields"
                    element={<CustomFields />}
                  ></Route>
                  <Route
                    path="custom-prompts"
                    element={<CustomPrompts />}
                  ></Route>
                  <Route
                    path="custom-prompts/:templateId"
                    element={<CustomPromptsTemplateEditor />}
                  ></Route>
                  <Route
                    path="team-management"
                    element={<TeamManagement />}
                  ></Route>
                  <Route path="billing" element={<Billing />}></Route>
                  <Route path="privacy" element={<Privacy />}></Route>
                  <Route
                    path="profileDetails"
                    element={<ProfileDetails />}
                  ></Route>
                  <Route path="security" element={<Security />}></Route>
                  <Route
                    path="system"
                    element={<System instituteData={institute} />}
                  ></Route>
                  <Route path="functionality">
                    <Route path="" element={<Functionality />}></Route>
                    <Route path="linkedin" element={<LinkedIn />}></Route>
                    <Route
                      path="resumebuilder"
                      element={<ResumeBuilderFunctionality />}
                    ></Route>
                  </Route>
                </Route>

                <Route
                  path="/tools"
                  element={
                    <div style={{ marginTop: '5rem' }}>
                      <ComingSoon featureName={'Tools'} checked={false} />
                    </div>
                  }
                />
                <Route
                  path="/userperm"
                  element={
                    <div style={{ marginTop: '5rem' }}>
                      <ComingSoon
                        featureName={'User and Permissions'}
                        checked={false}
                      />
                    </div>
                  }
                />
                <Route
                  path={`/${studentNameLowerCase}/:studentId`}
                  element={<StudentProviderLayout />}
                >
                  <Route
                    element={
                      <StudentLayout>
                        <StudentDetail />
                      </StudentLayout>
                    }
                  >
                    <Route path="" element={<StudentOverview />} />
                    <Route path="dashboard" element={<InstituteDashboard />} />
                    <Route
                      path="linkedin"
                      element={
                        <div>
                          <Linkedin />
                        </div>
                      }
                    />
                    <Route path="goals/:id" element={<StudentGoalDetails />} />
                    <Route path="notes" element={<PrivateNotes />} />
                    <Route
                      path="personal-details"
                      element={
                        <div style={{ marginTop: '5rem' }}>
                          <ProfilePage />
                        </div>
                      }
                    />
                    <Route path="board" element={<JobTracker />} />
                    <Route path="edit" element={<EditStudent />} />
                    <Route path="documents" element={<Documents />} />
                    <Route
                      path="resumebuilder"
                      element={
                        <ResumeBuilderList
                          handleClearResumeSettingsStore={
                            handleClearResumeSettingsStore
                          }
                        />
                      }
                    />

                    <Route path="goals" element={<StudentGoals />} />
                    <Route
                      path="contacts"
                      element={
                        <div style={{ height: '80vh' }}>
                          <ContactList />
                        </div>
                      }
                    />
                    <Route
                      path="contacts/:id"
                      element={
                        <div style={{ height: '80vh' }}>
                          <ContactList />
                        </div>
                      }
                    />
                    <Route path="coverLetter" element={<CoverLetter />} />
                    <Route path="elevatorPitch" element={<ElevatorPitch />} />
                    <Route path="postGenerator" element={<PostGenerator />} />
                    <Route
                      path="linkedinHeadlineGenerator"
                      element={<LinkedinHeadlineGenerator />}
                    />
                    <Route
                      path="linkedinAboutSectionGenerator"
                      element={<LinkedinAboutGenerator />}
                    />
                  </Route>
                </Route>
                <Route
                  path={`/${studentNameLowerCase}/add${studentNameLowerCase}`}
                  element={<AddStudent />}
                />

                <Route path="/coach/addcoach" element={<AddCoach />} />
                <Route path="/jobs" element={<JobsLib />} />
                <Route path="/groups" element={<Groups />} />

                <Route path="/coach/:coachId" element={<CoachDetail />}>
                  <Route path="" element={<CoachOverview />} />
                  <Route
                    path="*"
                    element={
                      <div
                        style={{
                          marginTop: '5rem',
                        }}
                      >
                        <ComingSoon checked={false} />
                      </div>
                    }
                  />
                  <Route path="edit" element={<EditCoach />} />
                </Route>
              </Route>
              <Route>
                <Route element={<StudentProviderLayout />}>
                  <Route
                    path={`/${studentNameLowerCase}/:studentId/resumebuilder/editor`}
                    element={
                      <StudentLayout>
                        <ResumeBuilder
                          handleClearResumeSettingsStore={
                            handleClearResumeSettingsStore
                          }
                        />
                      </StudentLayout>
                    }
                  />
                </Route>

                <Route
                  path="*"
                  element={<NotFound redirectTo={`${studentNameLowerCase}`} />}
                />
              </Route>
              <Route
                path="/settings/functionality/linkedin/add"
                element={<AddNewLinkedInCheckList />}
              />
              <Route
                path="/settings/functionality/resumebuilder/:analysisId"
                element={<AddNewResumeInCheckList />}
              />
              <Route
                path="/settings/functionality/resumebuilder/:analysisId/:sectionId"
                element={<AddNewResumeInCheckListPersonalInformation />}
              />
            </Routes>
          ) : (
            <Routes>
              {!appName && <Route element={<SelectInstitute />} path="/" />}

              <Route
                path="/login"
                element={
                  <AuthScreen
                    instituteLogo={institute?.logoUrl}
                    instituteName={institute?.name}
                  />
                }
              />
            </Routes>
          )}
        </Suspense>
      </div>
    );
}

export default App;

//linkedin and email overlap ellipsis sol
//document ready me line in between dots
