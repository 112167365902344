import {
  DownOutlined,
  FolderOutlined,
  LeftCircleOutlined,
  PhoneOutlined,
  RightCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Menu, Row } from 'antd';
import React, { useContext, useEffect, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext, InitialState } from '../../auth';
import { ReactComponent as Coach } from '../../images/Vectorcoach.svg';
import { ReactComponent as Vectordash } from '../../images/Vectordash.svg';
import { ReactComponent as Vectorjobs } from '../../images/Vectorjobs.svg';
import { ReactComponent as Vectorstudent } from '../../images/Vectorstudent.svg';
import { ReactComponent as Vectorreport } from '../../images/Vectorreport.svg';
import { ReactComponent as Flag } from '../../images/flag.svg';
import { ReactComponent as Groups } from '../../images/groups.svg';

import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { handleOnKeyDown } from '../../utils/eventListeners';
import { useOwnerAdminAccess } from '../RBAC/OwnerAdminAccess';
import TooltipParagraph from '../TooltipParagraph';
import './SideNav.less';

const SideNav = ({ shrink, setShrink }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedItem, setSelectedItem] = React.useState('/');
  const { studentName, institute, userInfo } = useContext(
    AppContext
  ) as InitialState;
  const appName = window.location.pathname.split('/')[1];
  const { t, i18n } = useTranslation();
  const hasOwnerAdminAccess = useOwnerAdminAccess();

  useEffect(() => {
    const pathParam = window.location.pathname.split('/')[2];
    setSelectedItem(`/${decodeURIComponent(pathParam)}`);
  }, [appName, location]);

  const onClickStudent = () => {
    navigate(`/${studentName.toLowerCase()}`);
  };

  const items3: ItemType[] = useMemo(
    () => [
      {
        key: '/dashboard',
        label: t('Dashboard'),
        icon: <Vectordash />,
        style: {
          color: selectedItem === '/dashboard' ? '#0062eb' : undefined,
        },
      },
      {
        key: 'student',
        label: `${studentName}s`,
        icon: <Vectorstudent />,
        style: {
          color:
            selectedItem === `/${studentName.toLowerCase()}`
              ? '#0062eb'
              : undefined,
        },
        title: '',
        children: [
          {
            key: `/${studentName.toLowerCase()}`,
            label: t(`All`),
            style: {
              color:
                selectedItem === `/${studentName.toLowerCase()}`
                  ? '#0062eb'
                  : undefined,
            },
            title: '',
            popupOffset: [16, 16],
          },
          {
            key: '/invites',
            label: t('Invited'),
            style: {
              color: selectedItem === '/invites' ? '#0062eb' : undefined,
            },
            title: studentName,
            popupOffset: [16, 16],
          },
        ],
      },
      {
        key: '/coach',
        label: t('Coaches'),
        icon: <Coach />,
        style: { color: selectedItem === '/coach' ? '#0062eb' : undefined },
        title: 'Coaches',
      },
      {
        key: '/jobs',
        label: t('Jobs'),
        icon: <Vectorjobs />,
        style: {
          color: selectedItem === '/settings' ? '#0062eb' : undefined,
        },
        title: 'Jobs',
      },
      {
        key: '/contacts',
        label: t('Contacts'),
        icon: (
          <PhoneOutlined
            style={{
              fontSize: '18px',
              height: '18px',
              color: 'rgb(150, 160, 181)',
            }}
          />
        ),
        style: {
          color: selectedItem === '/settings' ? '#0062eb' : undefined,
        },
        title: 'Contacts',
      },
      {
        key: '/resources',
        label: t('Resources'),
        icon: (
          <FolderOutlined
            style={{ fontSize: '20px', color: 'rgb(150, 160, 181)' }}
          />
        ),
      },
      {
        key: '/groups',
        label: t('Groups'),
        icon: <Groups />,
        style: { color: selectedItem === '/groups' ? '#0062eb' : undefined },
        title: 'Groups',
      },
      ...(institute?.isCustomChecklistEnabled
        ? [
            {
              key: '/checklists',
              label: t('Checklists'),
              icon: (
                <span
                  style={{ fontSize: '20px', color: '#96A0B5' }}
                  className="material-icons-outlined"
                >
                  checklist
                </span>
              ),
              style: {
                color: selectedItem === '/checklists' ? '#0062eb' : undefined,
              },
              title: 'Checklists',
            },
          ]
        : []),
      {
        key: '/goals',
        label: t('Goals'),
        icon: <Flag />,
        style: { color: selectedItem === '/flag' ? '#0062eb' : undefined },
        title: 'Goals',
      },
      ...(institute?.enableReports
        ? [
            {
              key: '/reports',
              label: t('Reports'),
              icon: <Vectorreport />,
              style: {
                color: selectedItem === '/reports' ? '#0062eb' : undefined,
              },
              title: 'Reports',
            },
          ]
        : []),
      ...(institute?.showSettings && hasOwnerAdminAccess
        ? [
            {
              key: '/settings',
              label: t('Settings'),
              icon: (
                <SettingOutlined
                  style={{ fontSize: '20px', color: '#96A0B5' }}
                />
              ),
              style: {
                color: selectedItem === '/settings' ? '#0062eb' : undefined,
              },
              title: 'Settings',
            },
          ]
        : []),
    ],
    [
      hasOwnerAdminAccess,
      institute?.isCustomChecklistEnabled,
      institute?.showSettings,
      institute?.enableReports,
      selectedItem,
      studentName,
      t,
    ]
  );

  const [menuItems, setMenuItems] = React.useState(items3);

  useEffect(() => {
    setMenuItems(items3);
    // i18n.changeLanguage(i18n.language)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    const newMenu = [...items3];
    if (institute?.goals && !items3.find((data) => data.key === '/goals')) {
      newMenu.splice(6, 0, {
        key: '/goals',
        label: t('Goals'),
        icon: <Flag />,
        style: { color: selectedItem === '/flag' ? '#0062eb' : undefined },
        title: '',
      });
    }
    if (institute?.student || studentName) {
      newMenu.splice(1, 1, {
        key: 'student',
        label: `${studentName}s`,
        icon: <Vectorstudent />,
        style: { color: selectedItem === '/student' ? '#0062eb' : undefined },
        title: '',
        children: [
          {
            key: `/${studentName.toLowerCase()}`,
            label: t(`All`),
            style: {
              color: selectedItem === '/student' ? '#0062eb' : undefined,
            },
            title: '',
          },
          {
            key: '/invites',
            label: t('Invited'),
            style: {
              color: selectedItem === '/invites' ? '#0062eb' : undefined,
            },
            title: '',
          },
        ],
      });
    }
    setMenuItems([...newMenu]);
  }, [institute, items3, selectedItem, studentName, t]);

  const handleInstituteChange = (value: string) => {
    localStorage.setItem('loggedInApp', value);
    window.location.replace(window.location.origin + '/' + value);
  };

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #CFD2D7',
        fontSize: '15px',
        color: '#96A0B5',
      }}
    >
      <div>
        <Row
          style={{
            height: '72px',
            borderBottom: '1px solid #F2F4F7',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'grid',
              alignItems: 'center',
              justifyContent: 'flex-start',
              cursor: 'pointer',
              gridTemplateColumns: shrink ? '100%' : '25% 75%',
              width: '100%',
            }}
            onClick={() => navigate(`/${studentName.toLowerCase()}`)}
            onKeyDown={(e) =>
              handleOnKeyDown(e, () =>
                navigate(`/${studentName.toLowerCase()}`)
              )
            }
            role="button"
            tabIndex={0}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0 8px 0 8px',
              }}
            >
              <img
                src={institute?.logoUrl}
                alt="logo"
                width={45}
                height={45}
                style={{ objectFit: 'contain' }}
              />
            </div>

            {shrink ? null : (
              <div className="institute-selector">
                {Object.keys(userInfo?.instituteRoles || {}).length > 1 ? (
                  <Dropdown
                    menu={{
                      items: [
                        ...Object.keys(userInfo?.instituteRoles || {})
                          .filter((item) => item !== appName)
                          .sort((a, b) => a.localeCompare(b))
                          .map((institute) => ({
                            key: institute,
                            label: (
                              <div
                                onClick={() => handleInstituteChange(institute)}
                                onKeyDown={(e) =>
                                  handleOnKeyDown(e, () =>
                                    handleInstituteChange(institute)
                                  )
                                }
                                role="button"
                                tabIndex={0}
                                style={{ width: '100%' }}
                              >
                                {institute}
                              </div>
                            ),
                          })),
                      ],
                    }}
                    trigger={['click']}
                    overlayStyle={{ maxHeight: '300px' }}
                    className="org-dropdown"
                    overlayClassName="org-dropdown-overlay"
                  >
                    <span
                      style={{
                        display: 'inline-flex',
                        gap: '1rem',
                        alignItems: 'center',
                      }}
                    >
                      <TooltipParagraph style={{ width: '78%' }}>
                        {institute?.name}
                      </TooltipParagraph>
                      <DownOutlined height={12} />
                    </span>
                  </Dropdown>
                ) : (
                  <TooltipParagraph>{institute?.name}</TooltipParagraph>
                )}
              </div>
            )}
          </div>
        </Row>
        <Menu
          mode="inline"
          onClick={(e) => {
            navigate(`${e.key}`);
            ReactGA.event({
              category: 'Side Nav',
              action: 'Navigate ' + e.key,
            });
          }}
          onOpenChange={() => (shrink ? null : onClickStudent())}
          style={{
            borderRight: 0,
            fontSize: '14px',
            color: '#152C5B',
            width: '92%',
            margin: 'auto',
            paddingTop: '1rem',
            fontWeight: '500',
          }}
          defaultSelectedKeys={['/']}
          selectedKeys={[selectedItem]}
          items={menuItems
            .filter((item) => institute?.goals || item.key !== '/goals')
            .map((item) => ({
              ...item,
              style: {
                ...item.style,
                color: item.key === selectedItem ? '#0062eb' : '#152C5B',
              },
            }))}
        />
      </div>
      <div
        style={{
          marginTop: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'center',
          gap: '1rem',
          paddingBottom: '1rem',
        }}
      >
        <Button
          size="small"
          onClick={() => setShrink(!shrink)}
          style={{ width: '100%' }}
          type="default"
        >
          {shrink ? <RightCircleOutlined /> : <LeftCircleOutlined />}
        </Button>
      </div>
    </div>
  );
};

export default SideNav;
