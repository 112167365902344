import ReactGA from 'react-ga4';
import { amplitude } from './amplitude';

export const analyticsEvent = (
  action: string,
  category?: string,
  eventProperties?: any
) => {
  ReactGA.event({
    category: category || '',
    action: action,
  });

  amplitude.getInstance().logEvent(`${action}`, {
    value: category,
    ...({ ...eventProperties, eventSource: 'webapp' } || {
      eventSource: 'webapp',
    }),
  });
};
